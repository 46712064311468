import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

export interface INewAltProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
}

const NewAlt: React.FC<INewAltProps> = ({ label, ...rest }) => {
  return (
    <Container {...rest}>{label !== undefined ? label : 'Novo'}</Container>
  );
};

export default NewAlt;
