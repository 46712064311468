export default {
  colors: {
    primary: '#ecd2a6',
    primaryDark: '#FF7A00',
    secondary: '#c9aa71',
    tertiary: '#88550A',

    white: '#fff',
    veryLightGrey: '#e4e4e4',
    lightGrey: '#fcfcfc',
    secondLightGrey: '#efefef',
    darkGrey: '#6c757d',
    grey: '#adadad',
    green: '#1b7e2b',
    greyLowerOpacity: '#abaebf',
    veryDarkGrey: '#747474',
    veryDarkGray: '#4e4e4e',
    slightlyDesaturatedOrange: '#c9aa71',
    darkModerateOrange: '#8a603a',
    veryDarkDesaturatedOrange: '#6d4c2d',
    darkGrayishOrange: '#817162',
    vividCyan: '#03bddf',
    darkGrayishBlue: '#80849e',

    background: '#fff',
    backgroundContainer: '#e7e7e7',

    success: '#20C05C',
    error: '#f7734e',
    warning: '#f7734e',
    warningHover: '#f66036',
    danger: '#d6360a',
    dangerHover: '#be3009',
    save: '#03bddf',
    saveHover: '#02a7c6',
    completion: '#44677b',
    completionHover: '#3b596b',
    edit: '#0292ad',
    delete: '#d6360a',
    view: '#fcb72c',
    fix: '#f7734e',
    lockUnlock: '#fcb72c',
    refresh: '#324c5a',
    addRow: '#0292ad',
    deleteRow: '#d6360a',
    report: '#1f6f45',
    dialogOptionDanger: '#d6360a',
    dialogOptionReport: '#1f6f45',
    dialogOptionNormal: '#c9aa71',
    dialogOptionSuccess: '#03bddf',
    notification: '#7d8839',
    extraValNotification: '#f5931d',
    download: '#06459a',
    downloadHover: '#053a81',

    label: '#dfdfdf',
    labelInput: '#80849e',
    labelCard: '#494f54',
    inputBorder: '#e4e4e4',
    inputDisabled: '#545454',

    inputIcon: '#cecece',
    iconProjects: '#fff',
    iconItems: '#fff',
    iconConfigurations: '#fff',
    iconManagement: '#fff',
    iconFolderProjects: '#fff',
    successUploadIcon: '#1b7e2b',
    errorUploadIcon: '#f7734e',
    urlIcon: '#6C757D',
    audioFileIcon: '#f4a104',
    pdfFileIcon: '#ad0b00',
    presentationFileIcon: '#cb4424',
    spreadsheetFileIcon: '#1e6e42',
    textFileIcon: '#000000',
    videoFileIcon: '#2938a7',
    wordFileIcon: '#005198',

    header: '#adadad',
    headerLight: '#c7c7c7',
    sideBar: '#686868',
    sideBarLight: '#686868',
    sideBarBorder: '#939393',
    sideBarButton: '#4e4e4e',
    footer: '#bababa',

    hoverTable: '#f9f9f9',
    optionsHover: '#4e4e4e',

    timelinefirsticon: '#93278e',

    chartDisplay: '#D53A53',
    chartMaterial: '#6b53bb',
    chartSponsorship: '#57C3A4',
    chartAdversity: '#2580de',
    chartFolder: '#dd9e3b',
    chartSample: '#8a9ca4',
    chartEvent: '#6c4826',
    chartPending: '#3198a2',
    chartFinished: '#153757',
    chartCanceled: '#c9bec4',
    chartPrice: '#f1c30f',
    chartShippingPrice: '#156e9d',
    chartTotalPrice: '#18734d',
    chartGrossDiscountPrice: '#f1c30f',
    chartTotalTaxDeductionsPrice: '#156e9d',
    chartNetDiscountPrice: '#18734d',
  },
  fontSizes: {
    input: '1.6rem',
    tableInput: '1.4rem',
    infoInput: '1.4rem',
    label: '1.5rem',
    infoLabel: '1.4rem',
    button: '1.6rem',
    tiny: '1rem',
    small: '1.2rem',
    default: '1.4rem',
    meddium: '1.5rem',
    large: '1.6rem',
    xLarge: '2.0rem',
    xxLarge: '2.6rem',
    xxxLarge: '3.2rem',
  },
  weight: {
    weak: '200',
    meddium: '300',
    default: '400',
    strong: '500',
    veryStrong: '1000',
  },
  spacing: {
    default: '1.6rem',
    vertical: '1.6rem 0',
    horizontal: '0 1.6rem',
    large: '2.4rem',
  },
  transition: {
    default: '180ms ease-in-out',
  },
  radius: {
    large: '1rem',
    meddium: '0.8rem',
    default: '0.6rem',
    small: '0.4rem',
    tiny: '0.2rem',
  },
  shadows: {
    veryStrong: '0 0.5rem 2rem rgba(0, 0, 0, 0.5)',
    strong: '0 0.5rem 2rem rgba(0, 0, 0, 0.3)',
    default: '0 0.5rem 2rem rgba(0, 0, 0, 0.08)',
    flat: '0 0.2rem 0.2rem rgba(0, 0, 0, 0.08)',
  },
};
