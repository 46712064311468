import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { FaExclamationCircle, FaUser } from 'react-icons/fa';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { string } from 'yup';

import { useTheme } from 'styled-components';

import BreadCrumb from '~/components/BreadCrumb';
import CurrencyInput from '~/components/Inputs/Currency';
import DefaultInput from '~/components/Inputs/Default';
import DefaultTooltip from '~/components/Tooltips/Default';
import Divider from '~/components/Divider';
import Toggle from '~/components/Toggle';
import Select from '~/components/Select';
import LockButton from '~/components/Buttons/Lock';
import UnlockButton from '~/components/Buttons/Unlock';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';
import NoticeDialog from '~/components/Dialogs/Notice';

import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  Content,
  Wrapper,
  ToggleWrapper,
  InputWithTTWrapper,
  InputWrapper,
  TooltipWrapper,
  ButtonContainer,
} from './styles';

interface IFormUser {
  name: string;
  email: string;
  password: string;
  erp_rep_code?: string;
  active: boolean;
  event_responsable: boolean;
  event_gifts_responsable: boolean;
  event_validator: boolean;
  admin_functions: boolean;
  enable_new_display_req: boolean;
  enable_new_material_req: boolean;
  enable_new_sponsorship_req: boolean;
  enable_new_problem_rep: boolean;
  enable_new_folder_req: boolean;
  enable_new_sample_req: boolean;
  enable_new_event_req: boolean;
  spons_gen_approval_perm: boolean;
  spons_gen_approval_min_price: string;
  spons_gen_approval_max_price: string;
  user_type: {
    value: string;
  };
}

interface IUserTypes {
  id: string;
  name: string;
  type: string;
}

interface ILocation {
  id: string;
  name: string;
  email: string;
  erp_rep_code: string;
  active: boolean;
  event_responsable: boolean;
  event_gifts_responsable: boolean;
  event_validator: boolean;
  admin_functions: boolean;
  enable_new_display_req: boolean;
  enable_new_material_req: boolean;
  enable_new_sponsorship_req: boolean;
  enable_new_problem_rep: boolean;
  enable_new_folder_req: boolean;
  enable_new_sample_req: boolean;
  enable_new_event_req: boolean;
  spons_gen_approval_perm: boolean;
  spons_gen_approval_min_price: number;
  spons_gen_approval_max_price: number;
  user_type_id: string;
}

const schema = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),
  email: Yup.string().required('Campo obrigatório').email('E-mail inválido'),
  password: Yup.string().when('$passwordEnabled', (passwordEnabled: string) => {
    if (passwordEnabled) {
      return string().required('Campo obrigatório');
    }
    return string().notRequired();
  }),
  user_type: Yup.object().shape({
    value: Yup.string().required('Campo obrigatório'),
  }),
  erp_rep_code: Yup.string().when(
    '$erpCodeEnabled',
    (erpCodeEnabled: string) => {
      if (erpCodeEnabled) {
        return string().required('Campo obrigatório');
      }
      return string().notRequired();
    },
  ),
});

const New: React.FC = () => {
  const history = useHistory();
  const { colors } = useTheme();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [userTypes, setUserTypes] = useState<IUserTypes[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const userTypeValue = useRef('');
  const userTypeLabel = useRef('Selecione...');
  const [selectedUserTypeId, setSelectedUserTypeId] = useState('');
  const [selectedUserType, setSelectedUserType] = useState('');
  const [repCodeError, setRepCodeError] = useState('');
  const [passwordEnabled, setPasswordEnabled] = useState<boolean>(
    state === undefined,
  );
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [isOpenNotice, setIsOpenNotice] = useState(false);
  const [titleNotice, setTitleNotice] = useState('');
  const [subTitleNotice, setSubTitleNotice] = useState('');

  const { handleSubmit, register, setValue, control, watch, errors } =
    useForm<IFormUser>({
      context: {
        passwordEnabled,
        erpCodeEnabled: selectedUserType === 'REP',
      },
      resolver: yupResolver(schema),
      defaultValues: {
        active: state?.active,
      },
    });

  useEffect(() => {
    api.get('/usertypes').then(response => {
      setUserTypes(response.data);
      setLoading(false);
    });

    register('user_type');
  }, [register]);

  useEffect(() => {
    if (userTypes) {
      if (userTypes.length > 0) {
        if (state !== undefined) {
          const userTp = userTypes.find(
            selected => selected.id === state.user_type_id,
          );

          if (userTp) {
            userTypeValue.current = userTp.id;
            userTypeLabel.current = userTp.name;

            setValue('user_type', { value: userTypeValue.current });
          }
        }
      }
    }
  }, [userTypes, state, setValue]);

  useEffect(() => {
    let id: string = selectedUserTypeId;

    if (id === '') {
      if (state !== undefined) {
        id = state.user_type_id;
      }
    }

    const selectedUser = userTypes?.find(selected => selected.id === id);
    if (selectedUser) {
      setSelectedUserType(selectedUser.type);
    }
  }, [userTypes, state, selectedUserTypeId]);

  useEffect(() => {
    if (selectedUserType !== '' && selectedUserType !== 'REP') {
      setValue('erp_rep_code', '', { shouldValidate: true });
    }
  }, [selectedUserType, setValue]);

  const options = userTypes?.map(userType => ({
    value: userType.id,
    label: `${userType.name}`,
  }));

  const submitForm = useCallback(
    async (data: IFormUser) => {
      try {
        if (state === undefined) {
          await api.post('/users', data);

          addToast({
            type: 'success',
            title: 'Cadastro realizado!',
            description: 'Usuário cadastrado com sucesso.',
          });
        } else {
          await api.put(`/users/${state.id}`, data);

          addToast({
            type: 'success',
            title: 'Cadastro atualizado!',
            description: 'Usuário atualizado com sucesso.',
          });
        }

        history.push('/users');
      } catch (error: any) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [addToast, history, state],
  );

  function handleUserType(
    userTypeValueReceived: string,
    userTypeLabelReceived: string,
  ): void {
    userTypeValue.current = userTypeValueReceived;
    userTypeLabel.current = userTypeLabelReceived;
    setValue(
      'user_type',
      { value: userTypeValueReceived },
      { shouldValidate: true },
    );
  }

  async function onValidateConfirmProcedure(): Promise<void> {
    if (selectedUserType === 'REP' && repCodeError !== '') {
      setTitleNotice('Código representante inválido!');
      setSubTitleNotice(
        'Não é possível gravar usuário com código de representante inválido!',
      );
      setIsOpenNotice(true);
    } else {
      setIsOpenConfirmProcedure(true);
    }
  }

  const onExit = useCallback(() => {
    history.push('/users');
  }, [history]);

  function validateErpRepCodeError(
    receivedError: string | undefined,
  ): string | undefined {
    if (selectedUserType === 'REP') {
      if (repCodeError !== '') {
        return repCodeError;
      }
    }

    return receivedError;
  }

  async function validateRepCode(fieldValue: string): Promise<void> {
    let foundRep = false;
    await api.get(`/representatives/code?code=${fieldValue}`).then(response => {
      if (response.data) {
        if (response.data.code) {
          if (response.data.code === fieldValue) {
            foundRep = true;
          }
        }
      }
    });

    if (foundRep) {
      setRepCodeError('');
    } else {
      setRepCodeError('Código não encontrado');
    }
  }

  function handlePasswordEnable(): React.ReactNode | null {
    if (state !== undefined) {
      return (
        <>
          {passwordEnabled ? (
            <UnlockButton iconSize={26} marginTop="1rem" type="button" />
          ) : (
            <LockButton
              iconSize={26}
              marginTop="1rem"
              type="button"
              onClick={() => {
                setPasswordEnabled(true);
              }}
            />
          )}
        </>
      );
    }

    return null;
  }

  return (
    <Container>
      <BreadCrumb
        main="Usuários"
        path="/users"
        firstChild={state === undefined ? 'Novo usuário' : 'Edição de usuário'}
        mainIcon={FaUser}
        firstChildIcon={FaUser}
        mainColored
      />

      <form onSubmit={handleSubmit(submitForm)}>
        <Content>
          <Wrapper style={{ width: 400 }}>
            <DefaultInput
              name="name"
              labelFor="name"
              labelText="Nome"
              type="text"
              defaultValue={state?.name}
              error={errors?.name?.message}
              ref={register}
              maxLength={60}
            />
          </Wrapper>

          <Wrapper style={{ width: 100 }}>
            <Controller
              render={props => (
                <Toggle
                  onChange={e => props.onChange(e)}
                  checked={props.value}
                />
              )}
              name="active"
              defaultValue
              control={control}
            />
          </Wrapper>

          <Wrapper style={{ width: 400 }}>
            <DefaultInput
              name="email"
              labelFor="email"
              labelText="E-mail"
              type="email"
              defaultValue={state?.email}
              error={errors?.email?.message}
              ref={register}
              maxLength={60}
            />
          </Wrapper>

          <Wrapper style={{ width: 280 }}>
            <Select
              label="Tipo"
              options={options}
              error={errors?.user_type?.value?.message}
              loading={loading}
              value={{
                value: userTypeValue.current,
                label: userTypeLabel.current,
              }}
              onSet={(e: any) => {
                handleUserType(e.value, e.label);
                setSelectedUserTypeId(e.value);
              }}
            />
          </Wrapper>

          <Wrapper style={{ width: 220 }}>
            <DefaultInput
              name="erp_rep_code"
              labelFor="erp_rep_code"
              labelText="Código Rep. ERP"
              type="text"
              defaultValue={state?.erp_rep_code}
              error={validateErpRepCodeError(errors?.erp_rep_code?.message)}
              ref={register}
              disabled={selectedUserType !== 'REP'}
              maxLength={6}
              onChange={e => validateRepCode(e.target.value)}
            />
          </Wrapper>

          <Wrapper style={{ width: 280 }}>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '100%' }}>
                <DefaultInput
                  name="password"
                  labelFor="password"
                  labelText="Senha"
                  isPassword
                  ref={register}
                  error={errors.password?.message}
                  validateInput
                  disabled={!passwordEnabled}
                  maxLength={30}
                />
              </div>

              {handlePasswordEnable()}
            </div>
          </Wrapper>
        </Content>

        <Divider mt={40} mb={20} title="Procedimentos de Solicitações" />

        <Content>
          <ToggleWrapper>
            <Controller
              render={props => (
                <Toggle
                  onChange={e => props.onChange(e)}
                  checked={props.value}
                  label="Aprov. Geral Pagamentos"
                />
              )}
              name="spons_gen_approval_perm"
              defaultValue={
                state?.spons_gen_approval_perm !== undefined
                  ? state.spons_gen_approval_perm
                  : false
              }
              control={control}
            />
          </ToggleWrapper>

          {watch('spons_gen_approval_perm') && (
            <>
              <InputWithTTWrapper>
                <InputWrapper style={{ width: 180 }}>
                  <CurrencyInput
                    name="spons_gen_approval_min_price"
                    labelFor="spons_gen_approval_min_price"
                    labelText="Valor Mín. Aprovação"
                    defaultValue={state?.spons_gen_approval_min_price ?? 0}
                    error={errors?.spons_gen_approval_min_price?.message}
                    ref={register}
                  />
                </InputWrapper>

                <TooltipWrapper>
                  <DefaultTooltip
                    title={
                      'Quando não for informado valor no campo, ' +
                      'não terá restrição de valor mínimo.'
                    }
                    background={colors.darkGrayishBlue}
                  >
                    <FaExclamationCircle size={20} />
                  </DefaultTooltip>
                </TooltipWrapper>
              </InputWithTTWrapper>

              <InputWithTTWrapper>
                <InputWrapper style={{ width: 180 }}>
                  <CurrencyInput
                    name="spons_gen_approval_max_price"
                    labelFor="spons_gen_approval_max_price"
                    labelText="Valor Máx. Aprovação"
                    defaultValue={state?.spons_gen_approval_max_price ?? 0}
                    error={errors?.spons_gen_approval_max_price?.message}
                    ref={register}
                  />
                </InputWrapper>

                <TooltipWrapper>
                  <DefaultTooltip
                    title={
                      'Quando não for informado valor no campo ' +
                      'ou o valor for abaixo do valor mínimo, ' +
                      'não terá restrição de valor máximo.'
                    }
                    background={colors.darkGrayishBlue}
                  >
                    <FaExclamationCircle size={20} />
                  </DefaultTooltip>
                </TooltipWrapper>
              </InputWithTTWrapper>
            </>
          )}

          <ToggleWrapper>
            <Controller
              render={props => (
                <Toggle
                  onChange={e => props.onChange(e)}
                  checked={props.value}
                  label="Resp. Eventos"
                />
              )}
              name="event_responsable"
              defaultValue={
                state?.event_responsable !== undefined
                  ? state.event_responsable
                  : false
              }
              control={control}
            />
          </ToggleWrapper>

          <ToggleWrapper>
            <Controller
              render={props => (
                <Toggle
                  onChange={e => props.onChange(e)}
                  checked={props.value}
                  label="Resp. Brindes Eventos"
                />
              )}
              name="event_gifts_responsable"
              defaultValue={
                state?.event_gifts_responsable !== undefined
                  ? state.event_gifts_responsable
                  : false
              }
              control={control}
            />
          </ToggleWrapper>

          <ToggleWrapper>
            <Controller
              render={props => (
                <Toggle
                  onChange={e => props.onChange(e)}
                  checked={props.value}
                  label="Validador Eventos"
                />
              )}
              name="event_validator"
              defaultValue={
                state?.event_validator !== undefined
                  ? state.event_validator
                  : false
              }
              control={control}
            />
          </ToggleWrapper>
        </Content>

        <Divider mt={40} mb={20} title="Habilitação de Solicitações" />

        <Content>
          <ToggleWrapper>
            <Controller
              render={props => (
                <Toggle
                  onChange={e => props.onChange(e)}
                  checked={props.value}
                  label="Hab. Nova Sol. Display"
                />
              )}
              name="enable_new_display_req"
              defaultValue={
                state?.enable_new_display_req !== undefined
                  ? state.enable_new_display_req
                  : false
              }
              control={control}
            />
          </ToggleWrapper>

          <ToggleWrapper>
            <Controller
              render={props => (
                <Toggle
                  onChange={e => props.onChange(e)}
                  checked={props.value}
                  label="Hab. Nova Sol. Material"
                />
              )}
              name="enable_new_material_req"
              defaultValue={
                state?.enable_new_material_req !== undefined
                  ? state.enable_new_material_req
                  : false
              }
              control={control}
            />
          </ToggleWrapper>

          <ToggleWrapper>
            <Controller
              render={props => (
                <Toggle
                  onChange={e => props.onChange(e)}
                  checked={props.value}
                  label="Hab. Nova Sol. Pagamento"
                />
              )}
              name="enable_new_sponsorship_req"
              defaultValue={
                state?.enable_new_sponsorship_req !== undefined
                  ? state.enable_new_sponsorship_req
                  : false
              }
              control={control}
            />
          </ToggleWrapper>

          <ToggleWrapper>
            <Controller
              render={props => (
                <Toggle
                  onChange={e => props.onChange(e)}
                  checked={props.value}
                  label="Hab. Novo Rep. Problema"
                />
              )}
              name="enable_new_problem_rep"
              defaultValue={
                state?.enable_new_problem_rep !== undefined
                  ? state.enable_new_problem_rep
                  : false
              }
              control={control}
            />
          </ToggleWrapper>

          <ToggleWrapper>
            <Controller
              render={props => (
                <Toggle
                  onChange={e => props.onChange(e)}
                  checked={props.value}
                  label="Hab. Nova Sol. Pasta"
                />
              )}
              name="enable_new_folder_req"
              defaultValue={
                state?.enable_new_folder_req !== undefined
                  ? state.enable_new_folder_req
                  : false
              }
              control={control}
            />
          </ToggleWrapper>

          <ToggleWrapper>
            <Controller
              render={props => (
                <Toggle
                  onChange={e => props.onChange(e)}
                  checked={props.value}
                  label="Hab. Nova Sol. Amostra"
                />
              )}
              name="enable_new_sample_req"
              defaultValue={
                state?.enable_new_sample_req !== undefined
                  ? state.enable_new_sample_req
                  : false
              }
              control={control}
            />
          </ToggleWrapper>

          <ToggleWrapper>
            <Controller
              render={props => (
                <Toggle
                  onChange={e => props.onChange(e)}
                  checked={props.value}
                  label="Hab. Nova Sol. Evento"
                />
              )}
              name="enable_new_event_req"
              defaultValue={
                state?.enable_new_event_req !== undefined
                  ? state.enable_new_event_req
                  : false
              }
              control={control}
            />
          </ToggleWrapper>
        </Content>

        <Divider mt={40} mb={20} title="Sistema" />

        <Content>
          <ToggleWrapper>
            <Controller
              render={props => (
                <Toggle
                  onChange={e => props.onChange(e)}
                  checked={props.value}
                  label="Funções Administrativas"
                />
              )}
              name="admin_functions"
              defaultValue={
                state?.admin_functions !== undefined
                  ? state.admin_functions
                  : false
              }
              control={control}
            />
          </ToggleWrapper>
        </Content>

        <ButtonContainer>
          <SaveButton
            type="button"
            onClick={() => onValidateConfirmProcedure()}
          />

          <ExitButton
            marginLeft="1rem"
            type="button"
            onClick={() => setIsOpenExit(true)}
          />
        </ButtonContainer>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={() => handleSubmit(submitForm)()}
        title="Confirma gravação do usuário?"
        subTitle=""
        opConfirm="Sim"
        type=""
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />

      <NoticeDialog
        isOpen={isOpenNotice}
        type="error"
        title={titleNotice}
        subTitle={subTitleNotice}
        setIsOpen={setIsOpenNotice}
      />
    </Container>
  );
};

export default New;
