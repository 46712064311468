import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FaTools, FaWrench } from 'react-icons/fa';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import BreadCrumb from '~/components/BreadCrumb';
import ProjectInfo from '~/components/ProjectInfo/Default';
import DefaultInput from '~/components/Inputs/Default';
import TextArea from '~/components/TextArea';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';
import NoticeDialog from '~/components/Dialogs/Notice';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  Content,
  ContentSplit,
  Wrapper,
  HalfWrapper,
  ButtonContainer,
} from './styles';

interface IFormErpRepClient {
  erp_rep_code: string;
  erp_client_code: string;
  reason: string;
  received_user_id: string;
}

interface IRepresentative {
  code: string;
  name: string;
}

interface IClient {
  code: string;
  name: string;
  company_name: string;
  document: string;
  address: string;
  city: string;
  state: string;
  sales_channel_code: string;
}

interface ILocation {
  id: string;
  code: string;
  erp_rep_code: string;
  erp_client_code: string;
}

const schema = Yup.object().shape({
  erp_rep_code: Yup.string().required('Campo obrigatório'),
  erp_client_code: Yup.string().required('Campo obrigatório'),
  reason: Yup.string().required('Campo obrigatório'),
});

const ErpRepClient: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [titleConfirmProcedure, setTitleConfirmProcedure] = useState('');
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [isOpenNotice, setIsOpenNotice] = useState(false);
  const [titleNotice, setTitleNotice] = useState('');
  const [subTitleNotice, setSubTitleNotice] = useState('');
  const representative = useRef<IRepresentative>();
  const client = useRef<IClient>();
  const [representativeName, setRepresentativeName] =
    useState('Não Encontrado');
  const [clientName, setClientName] = useState('Não Encontrado');

  const { handleSubmit, register, setValue, errors } =
    useForm<IFormErpRepClient>({
      resolver: yupResolver(schema),
    });

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Dados não localizados',
      });

      history.push('/management/main');
    } else if (user.user_type.type !== 'ADM') {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Tipo de usuário não permitido',
      });

      history.push('/management/main');
    } else {
      register('received_user_id');

      setValue('received_user_id', user.id);
    }
  }, [state, addToast, history, user, register, setValue]);

  const submitForm = useCallback(
    async (data: IFormErpRepClient) => {
      try {
        if (state && state.id) {
          await api.put(`/projects/updateerprepclient/${state.id}`, data);

          addToast({
            type: 'success',
            title: 'Solicitação atualizada!',
            description: 'Solicitação atualizada com sucesso.',
          });
        }

        history.push('/management/main');
      } catch (error: any) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [state, addToast, history],
  );

  async function onValidateConfirmProcedure(): Promise<void> {
    if (!representative.current) {
      setTitleNotice('Representante não encontrado!');
      setSubTitleNotice('Necessário informar código de representante válido!');
      setIsOpenNotice(true);
    } else if (!client.current) {
      setTitleNotice('Cliente não encontrado!');
      setSubTitleNotice('Necessário informar código de cliente válido!');
      setIsOpenNotice(true);
    } else {
      setTitleConfirmProcedure(
        `Confirma alteração de Representante/Cliente na solicitação ${state.code}?`,
      );
      setIsOpenConfirmProcedure(true);
    }
  }

  function onConfirmProcedure(): void {
    handleSubmit(submitForm)();
  }

  const onExit = useCallback(() => {
    history.push('/management/main');
  }, [history]);

  function handleErpRepCodeChange(value: string): void {
    api.get(`/representatives/code?code=${value}`).then(response => {
      representative.current = response.data;

      if (representative.current && representative.current.name) {
        setRepresentativeName(representative.current.name);
      } else {
        setRepresentativeName('Não Encontrado');
      }
    });
  }

  function handleErpClientCodeChange(value: string): void {
    api.get(`/clients/code?code=${value}`).then(response => {
      client.current = response.data;

      if (client.current && client.current.name) {
        setClientName(client.current.name);
      } else {
        setClientName('Não Encontrado');
      }
    });
  }

  return (
    <Container>
      <BreadCrumb
        main="Administração"
        path="/management/main"
        firstChild="Alteração Manual de Solicitação"
        mainIcon={FaTools}
        firstChildIcon={FaWrench}
        mainColored
      />

      <ProjectInfo project_id={state.id} all />

      <form onSubmit={handleSubmit(submitForm)}>
        <ContentSplit maxWidth="600px">
          <HalfWrapper>
            <DefaultInput
              name="old_erp_rep_code"
              labelFor="old_erp_rep_code"
              labelText="Código Representante Antigo"
              type="text"
              value={state?.erp_rep_code}
              disabled
            />
          </HalfWrapper>

          <HalfWrapper>
            <DefaultInput
              name="old_erp_client_code"
              labelFor="old_erp_client_code"
              labelText="Código Cliente Antigo"
              type="text"
              value={state?.erp_client_code}
              disabled
            />
          </HalfWrapper>
        </ContentSplit>

        <ContentSplit maxWidth="600px">
          <HalfWrapper>
            <DefaultInput
              name="erp_rep_code"
              labelFor="_erp_rep_code"
              labelText="Código Representante Novo"
              type="text"
              error={errors?.erp_rep_code?.message}
              ref={register}
              maxLength={5}
              onChange={event => handleErpRepCodeChange(event.target.value)}
            />
          </HalfWrapper>

          <HalfWrapper>
            <DefaultInput
              name="erp_client_code"
              labelFor="erp_client_code"
              labelText="Código Cliente Novo"
              type="text"
              error={errors?.erp_client_code?.message}
              ref={register}
              maxLength={5}
              onChange={event => handleErpClientCodeChange(event.target.value)}
            />
          </HalfWrapper>
        </ContentSplit>

        <ContentSplit maxWidth="600px">
          <HalfWrapper>
            <DefaultInput
              name="rep_name"
              labelFor="rep_name"
              labelText="Nome Representante Novo"
              type="text"
              value={representativeName}
              disabled
            />
          </HalfWrapper>

          <HalfWrapper>
            <DefaultInput
              name="client_name"
              labelFor="client_name"
              labelText="Nome Cliente Novo"
              type="text"
              value={clientName}
              disabled
            />
          </HalfWrapper>
        </ContentSplit>

        <Content maxWidth="600px">
          <Wrapper>
            <TextArea
              name="reason"
              labelFor="reason"
              labelText="Motivo"
              error={errors?.reason?.message}
              ref={register}
              maxLength={1000}
            />
          </Wrapper>
        </Content>

        <ButtonContainer>
          <SaveButton
            type="button"
            onClick={() => onValidateConfirmProcedure()}
          />

          <ExitButton
            marginLeft="1rem"
            type="button"
            onClick={() => setIsOpenExit(true)}
          />
        </ButtonContainer>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={onConfirmProcedure}
        title={titleConfirmProcedure}
        subTitle=""
        opConfirm="Sim"
        type=""
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />

      <NoticeDialog
        isOpen={isOpenNotice}
        type="error"
        title={titleNotice}
        subTitle={subTitleNotice}
        setIsOpen={setIsOpenNotice}
      />
    </Container>
  );
};

export default ErpRepClient;
