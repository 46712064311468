import React, { useCallback, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FaTools, FaTrashAlt } from 'react-icons/fa';

import BreadCrumb from '~/components/BreadCrumb';
import ProjectInfo from '~/components/ProjectInfo/Default';
import DeleteAltButton from '~/components/Buttons/DeleteAlt';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmDeleteDialog from '~/components/Dialogs/ConfirmDelete';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import { Container, Content, ButtonContainer } from './styles';

interface ILocation {
  id: string;
}

const Deletion: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Dados não localizados',
      });

      history.push('/management/main');
    } else if (user.user_type.type !== 'ADM') {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Tipo de usuário não permitido',
      });

      history.push('/management/main');
    }
  }, [state, addToast, history, user]);

  const onDelete = useCallback(
    async (id: string) => {
      try {
        await api.delete(`/projects/${id}`).then(() => {
          addToast({
            type: 'success',
            title: 'Solicitação excluída!',
            description: 'Solicitação excluída com sucesso.',
          });

          history.push('/management/main');
        });
      } catch (error: any) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [addToast, history],
  );

  const onExit = useCallback(() => {
    history.push('/management/main');
  }, [history]);

  return (
    <Container>
      <BreadCrumb
        main="Administração"
        path="/management/main"
        firstChild="Exclusão de Solicitação"
        mainIcon={FaTools}
        firstChildIcon={FaTrashAlt}
        mainColored
      />

      <ProjectInfo project_id={state.id} all />

      <Content style={{ maxWidth: '600px' }}>
        <DeleteAltButton onClick={() => setIsOpenDelete(true)} />
      </Content>

      <ButtonContainer>
        <ExitButton marginLeft="1rem" type="button" onClick={() => onExit()} />
      </ButtonContainer>

      <ConfirmDeleteDialog
        isOpen={isOpenDelete}
        title="Deseja realmente excluir a solicitação?"
        subTitle={
          'Serão eliminados permanentemente todos ' +
          'os registros e arquivos relacionados à ' +
          'solicitação. Esta operação não poderá ser desfeita!'
        }
        setIsOpen={setIsOpenDelete}
        onClose={() => {
          onDelete(state.id);
        }}
      />
    </Container>
  );
};

export default Deletion;
