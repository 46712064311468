import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { PropagateLoader } from 'react-spinners';
import * as mime from 'mime';
import { FaStore, FaBox, FaLink } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';

import BreadCrumb from '~/components/BreadCrumb';
import StoreRelationshipInfo from '~/components/StoreRelationshipInfo';
import TableDateInput from '~/components/TableInputs/Date';
import TableSelect from '~/components/TableSelect';
import TableTextArea from '~/components/TableTextArea';
import AudioIcon from '~/components/Icons/Files/Audio';
import PdfIcon from '~/components/Icons/Files/Pdf';
import PresentationIcon from '~/components/Icons/Files/Presentation';
import SpreadsheetIcon from '~/components/Icons/Files/Spreadsheet';
import TextIcon from '~/components/Icons/Files/Text';
import VideoIcon from '~/components/Icons/Files/Video';
import WordIcon from '~/components/Icons/Files/Word';
import LockButton from '~/components/Buttons/Lock';
import UnlockButton from '~/components/Buttons/Unlock';
import AddRowButton from '~/components/Buttons/AddRow';
import DeleteRowButton from '~/components/Buttons/DeleteRow';
import DeletePreviousButton from '~/components/Buttons/DeletePrevious';
import UploadStoreRelationshipDisplayFiles from '~/components/UploadStoreRelationshipDisplayFiles';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';
import ConfirmWarningDialog from '~/components/Dialogs/ConfirmWarning';
import DeleteModal from '~/components/Modals/Delete';
import NoticeDialog from '~/components/Dialogs/Notice';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  Content,
  ContentAddRow,
  LabelContainer,
  Label,
  Table,
  ItemSeparator,
  ItemContent,
  ItemWrapper,
  ItemUploadLabel,
  ItemUploadWrapper,
  ItemPreviousFilesWrapper,
  FileMainContainer,
  FileImageWrapper,
  FileImage,
  FileInfoName,
  FileInfoFileSize,
  FileInfoFileTypeDescription,
  FileUrlIcon,
  ButtonContainer,
} from './styles';

interface IPreviousStoreRelationshipDisplay {
  id: string;
  completion_date: Date;
  record_type: string;
  note: string;
  display_id: string;
  store_relationship_id: string;
  project_id: string;
  user_inclusion_id: string;
  user_update_id: string;
  user_inclusion: {
    name: string;
  };
  user_update: {
    name: string;
  };
  created_at: string;
}

interface IPreviousStoreRelationshipDisplayFile {
  id: string;
  name: string;
  size: number;
  key: string;
  url: string;
  file_type_description: string;
  store_relationship_display_id: string;
  user_id: string;
  store_relationship_display_file_received_id: string;
  store_relationship_display: {
    id: string;
    code: number;
  };
  user: {
    id: string;
    name: string;
    type: string;
  };
  created_at: string;
  deleted: boolean;
}

interface IStoreRelationshipDisplayFileDeleted {
  id: string;
}

interface IStoreRelationshipDisplay {
  id: string;
  completion_date: Date;
  record_type: string;
  note: string;
  display_id: string;
  store_relationship_id: string;
  project_id?: string;
  user_inclusion_id: string;
  user_update_id: string;
  store_relationship_record_reason: string;
  previous: boolean;
  deleted: boolean;
  fields_enabled: boolean;
  store_relationship_display_temp_id: string;
  completion_date_error: string | undefined;
  created_at: Date;
  store_relationship_display_files_deleted: IStoreRelationshipDisplayFileDeleted[];
}

interface IFormProcedureDisplays {
  user_inclusion_id: string;
  user_update_id: string;
  record_description: string;
  store_relationship_displays: IStoreRelationshipDisplay[];
}

interface IDisplays {
  id: string;
  name: string;
  code: string;
}

interface IClient {
  code: string;
  name: string;
  company_name: string;
  document: string;
  address: string;
  city: string;
  state: string;
  rep_code: string;
  rep_name: string;
  sales_channel_code: string;
}

interface ILocation {
  id: string;
  code: string;
  erp_client_code: string;
  erp_rep_code: string;
  store_relationship_category_id: string;
}

const ProcedureDisplays: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [displays, setDisplays] = useState<IDisplays[]>();
  const [client, setClient] = useState<IClient>();
  const [
    previousStoreRelationshipDisplays,
    setPreviousStoreRelationshipDisplays,
  ] = useState<IPreviousStoreRelationshipDisplay[]>();
  const [
    previousStoreRelationshipDisplayFiles,
    setPreviousStoreRelationshipDisplayFiles,
  ] = useState<IPreviousStoreRelationshipDisplayFile[]>([]);
  const [
    previousStoreRelationshipDisplayFilesReceived,
    setPreviousStoreRelationshipDisplayFilesReceived,
  ] = useState<IPreviousStoreRelationshipDisplayFile[]>();
  const [storeRelationshipDisplays, setStoreRelationshipDisplays] = useState<
    IStoreRelationshipDisplay[]
  >([]);
  const [loadStoreRelationshipDisplays, setloadStoreRelationshipDisplays] =
    useState(false);
  const [storeRelationshipDisplaysLoaded, setStoreRelationshipDisplaysLoaded] =
    useState(false);
  const [loadingDisplays, setLoadingDisplays] = useState<boolean>(true);
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [titleConfirmProcedure, setTitleConfirmProcedure] = useState('');
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const [
    isOpenDeleteStoreRelationshipDisplay,
    setIsOpenDeleteStoreRelationshipDisplay,
  ] = useState(false);
  const [
    titleDeleteStoreRelationshipDisplay,
    setTitleDeleteStoreRelationshipDisplay,
  ] = useState('');
  const [
    subTitleDeleteStoreRelationshipDisplay,
    setSubTitleDeleteStoreRelationshipDisplay,
  ] = useState('');
  const [
    reasonDeleteStoreRelationshipDisplay,
    setReasonDeleteStoreRelationshipDisplay,
  ] = useState('');
  const [
    isOpenDeleteStoreRelationshipDisplayFile,
    setIsOpenDeleteStoreRelationshipDisplayFile,
  ] = useState(false);
  const [
    titleDeleteStoreRelationshipDisplayFile,
    setTitleDeleteStoreRelationshipDisplayFile,
  ] = useState('');
  const [isOpenNotice, setIsOpenNotice] = useState(false);
  const [titleNotice, setTitleNotice] = useState('');
  const [subTitleNotice, setSubTitleNotice] = useState('');
  const [
    storeRelationshipDisplayEnableTempId,
    setStoreRelationshipDisplayEnableTempId,
  ] = useState('');
  const [
    confirmStoreRelationshipDisplayEnabled,
    setConfirmStoreRelationshipDisplayEnabled,
  ] = useState(false);
  const [
    storeRelationshipDisplayDeleteTempId,
    setStoreRelationshipDisplayDeleteTempId,
  ] = useState('');
  const [
    confirmStoreRelationshipDisplayDelete,
    setConfirmStoreRelationshipDisplayDelete,
  ] = useState(false);
  const [
    storeRelationshipDisplayFileDeleteId,
    setStoreRelationshipDisplayFileDeleteId,
  ] = useState('');
  const [
    confirmStoreRelationshipDisplayFileDelete,
    setConfirmStoreRelationshipDisplayFileDelete,
  ] = useState(false);

  const { handleSubmit, register, setValue } =
    useForm<IFormProcedureDisplays>();

  useEffect(() => {
    if (previousStoreRelationshipDisplays) {
      if (!storeRelationshipDisplaysLoaded) {
        if (displays) {
          setloadStoreRelationshipDisplays(true);
        }
      }
    }
  }, [
    storeRelationshipDisplaysLoaded,
    previousStoreRelationshipDisplays,
    displays,
    state,
  ]);

  async function getStoreRelationshipDisplays(
    store_relationship_id: string,
  ): Promise<void> {
    await api
      .get(
        `/storerelationshipdisplays/storerelationshipall` +
          `?store_relationship_id=${store_relationship_id}`,
      )
      .then(response => {
        setPreviousStoreRelationshipDisplays(response.data);
      });
  }

  useEffect(() => {
    let emptyStoreRelationship = true;

    if (state && state.id) {
      getStoreRelationshipDisplays(state.id);
      emptyStoreRelationship = false;
    }

    if (emptyStoreRelationship) {
      if (!storeRelationshipDisplaysLoaded) {
        setloadStoreRelationshipDisplays(true);
      }
    }
  }, [state, storeRelationshipDisplaysLoaded]);

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Registro de loja não localizado',
      });

      history.push('/storerelationships');
    } else if (
      user.user_type.type !== 'REP' &&
      user.user_type.type !== 'ADM' &&
      user.user_type.type !== 'MK1' &&
      user.user_type.type !== 'MK2' &&
      user.user_type.type !== 'MAX' &&
      user.user_type.type !== 'GMK' &&
      user.user_type.type !== 'DGR' &&
      user.user_type.type !== 'DCM' &&
      user.user_type.type !== 'IMK' &&
      user.user_type.type !== 'GCM'
    ) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Tipo de usuário não permitido',
      });

      history.push('/storerelationships');
    } else {
      register('user_update_id');
      register('record_description');
      register('store_relationship_displays');

      setValue('user_update_id', user.id);

      api.get('/items/displays/valid').then(response => {
        setDisplays(response.data);
        setLoadingDisplays(false);
      });

      api.get(`/clients/code?code=${state.erp_client_code}`).then(response => {
        setClient(response.data);
      });
    }
  }, [addToast, history, state, user, register, setValue]);

  useEffect(() => {
    if (previousStoreRelationshipDisplayFilesReceived) {
      if (previousStoreRelationshipDisplayFilesReceived.length > 0) {
        previousStoreRelationshipDisplayFilesReceived.map(
          prevStoreRelationshipDisplayFileReceived => {
            let fileInserted = false;

            const previousStoreRelationshipDisplayFilesValidation =
              previousStoreRelationshipDisplayFiles.filter(
                prevStoreRelationshipDisplayFile =>
                  prevStoreRelationshipDisplayFile.id ===
                  prevStoreRelationshipDisplayFileReceived.id,
              );

            if (previousStoreRelationshipDisplayFilesValidation) {
              if (previousStoreRelationshipDisplayFilesValidation.length > 0) {
                fileInserted = true;
              }
            }

            if (!fileInserted) {
              setPreviousStoreRelationshipDisplayFiles(
                prevStoreRelationshipDisplayFiles => [
                  ...prevStoreRelationshipDisplayFiles,
                  prevStoreRelationshipDisplayFileReceived,
                ],
              );
            }

            return prevStoreRelationshipDisplayFileReceived;
          },
        );
      }
    }
  }, [
    previousStoreRelationshipDisplayFilesReceived,
    previousStoreRelationshipDisplayFiles,
  ]);

  async function getPreviousStoreRelationshipDisplayFiles(
    previous_store_rel_display_id: string,
  ): Promise<void> {
    if (previous_store_rel_display_id && previous_store_rel_display_id !== '') {
      await api
        .get(
          `/storerelationshipdisplayfiles/storerelationshipdisplayall?` +
            `store_relationship_display_id=${previous_store_rel_display_id}`,
        )
        .then(response => {
          setPreviousStoreRelationshipDisplayFilesReceived(response.data);
        });
    }
  }

  useEffect(() => {
    if (loadStoreRelationshipDisplays) {
      if (previousStoreRelationshipDisplays) {
        if (previousStoreRelationshipDisplays.length > 0) {
          Object.keys(previousStoreRelationshipDisplays).forEach(key => {
            const previousStoreRelationshipDisplay: IStoreRelationshipDisplay =
              {
                id: previousStoreRelationshipDisplays[Number(key)].id,
                completion_date: new Date(
                  previousStoreRelationshipDisplays[
                    Number(key)
                  ].completion_date,
                ),
                record_type:
                  previousStoreRelationshipDisplays[Number(key)].record_type,
                note: previousStoreRelationshipDisplays[Number(key)].note,
                display_id:
                  previousStoreRelationshipDisplays[Number(key)].display_id,
                store_relationship_id:
                  previousStoreRelationshipDisplays[Number(key)]
                    .store_relationship_id,
                project_id:
                  previousStoreRelationshipDisplays[Number(key)].project_id,
                user_inclusion_id:
                  previousStoreRelationshipDisplays[Number(key)]
                    .user_inclusion_id,
                user_update_id: user.id,
                store_relationship_record_reason: '',
                previous: true,
                deleted: false,
                fields_enabled: false,
                store_relationship_display_temp_id: uuidv4(),
                completion_date_error: undefined,
                created_at: new Date(
                  previousStoreRelationshipDisplays[Number(key)].created_at,
                ),
                store_relationship_display_files_deleted: [],
              };

            setStoreRelationshipDisplays(prevStoreRelationshipDisplays => [
              ...prevStoreRelationshipDisplays,
              previousStoreRelationshipDisplay,
            ]);

            getPreviousStoreRelationshipDisplayFiles(
              previousStoreRelationshipDisplays[Number(key)].id,
            );
          });
        }
      }

      const firstStoreRelationshipDisplay: IStoreRelationshipDisplay = {
        id: '',
        completion_date: new Date(new Date().setHours(0, 0, 0, 0)),
        record_type: 'Manual',
        note: '',
        display_id: '',
        store_relationship_id: state.id,
        project_id: undefined,
        user_inclusion_id: user.id,
        user_update_id: user.id,
        store_relationship_record_reason: '',
        previous: false,
        deleted: false,
        fields_enabled: true,
        store_relationship_display_temp_id: uuidv4(),
        completion_date_error: undefined,
        created_at: new Date(),
        store_relationship_display_files_deleted: [],
      };

      setStoreRelationshipDisplays(prevStoreRelationshipDisplays => [
        ...prevStoreRelationshipDisplays,
        firstStoreRelationshipDisplay,
      ]);

      setloadStoreRelationshipDisplays(false);
      setStoreRelationshipDisplaysLoaded(true);
      setLoadingDisplays(false);
    }
  }, [
    state,
    user,
    loadStoreRelationshipDisplays,
    previousStoreRelationshipDisplays,
  ]);

  const displayOptions = displays?.map(display => ({
    value: display.id,
    label: `(${display.code}) - ${display.name}`,
  }));

  function onConfirmWarning(): void {
    setConfirmStoreRelationshipDisplayEnabled(true);
  }

  function onDeleteStoreRelationshipDisplay(): void {
    setConfirmStoreRelationshipDisplayDelete(true);
  }

  function onDeleteStoreRelationshipDisplayFile(): void {
    setConfirmStoreRelationshipDisplayFileDelete(true);
  }

  const submitForm = useCallback(
    async (data: IFormProcedureDisplays) => {
      try {
        if (state !== undefined && state.id) {
          await api.put(`/storerelationships/${state.id}`, data);

          addToast({
            type: 'success',
            title: 'Displays de registro de loja atualizados!',
            description:
              'Displays de registro de loja atualizados com sucesso.',
          });

          history.push({
            pathname: '/storerelationships/procedure1',
            state: client,
          });
        } else {
          addToast({
            type: 'error',
            title: 'Registro inválido!',
            description: 'Registro de loja não localizado',
          });
        }
      } catch (error: any) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [addToast, history, client, state],
  );

  async function onValidateConfirm(): Promise<void> {
    const rowsEmptyDisplay = storeRelationshipDisplays.filter(
      storeRelDisplay =>
        storeRelDisplay.display_id === undefined ||
        storeRelDisplay.display_id === '',
    );

    const rowsInvalidCompletionDate = storeRelationshipDisplays.filter(
      storeRelDisplay => storeRelDisplay.completion_date_error !== undefined,
    );

    const rowsEmptyNote = storeRelationshipDisplays.filter(
      storeRelDisplay => storeRelDisplay.note === '',
    );

    if (rowsEmptyDisplay && rowsEmptyDisplay.length > 0) {
      setTitleNotice('Existem itens sem tipo de display selecionado!');
      setSubTitleNotice(
        'Não é possível gravar registro com itens sem tipo selecionado!',
      );
      setIsOpenNotice(true);
    } else if (
      rowsInvalidCompletionDate &&
      rowsInvalidCompletionDate.length > 0
    ) {
      setTitleNotice('Existem itens com datas de conclusão inválidas!');
      setSubTitleNotice(
        'Não é possível gravar registro com itens possuindo datas de conclusão inválidas!',
      );
      setIsOpenNotice(true);
    } else if (rowsEmptyNote && rowsEmptyNote.length > 0) {
      setTitleNotice('Existem itens com observação em branco!');
      setSubTitleNotice(
        'Não é possível gravar registro com itens possuindo observação em branco!',
      );
      setIsOpenNotice(true);
    } else {
      setTitleConfirmProcedure(
        `Confirma gravação de displays do registro ${state.code}?`,
      );

      setIsOpenConfirmProcedure(true);
    }
  }

  function onConfirmProcedure(): void {
    setValue(
      'record_description',
      'Atualização de displays do registro de loja',
    );

    setValue('store_relationship_displays', storeRelationshipDisplays);

    handleSubmit(submitForm)();
  }

  const onExit = useCallback(() => {
    storeRelationshipDisplays.map(storeRelDisplay => {
      api.delete(
        `storerelationshipdisplayfilesreceived/` +
          `storerelationshipdisplaytempid/` +
          `${storeRelDisplay.store_relationship_display_temp_id}`,
      );

      return storeRelDisplay;
    });

    history.push({
      pathname: '/storerelationships/procedure1',
      state: client,
    });
  }, [history, client, storeRelationshipDisplays]);

  function handleAddRowTable(): void {
    const newStoreRelationshipDisplay: IStoreRelationshipDisplay = {
      id: '',
      completion_date: new Date(new Date().setHours(0, 0, 0, 0)),
      record_type: 'Manual',
      note: '',
      display_id: '',
      store_relationship_id: state.id,
      project_id: undefined,
      user_inclusion_id: user.id,
      user_update_id: user.id,
      store_relationship_record_reason: '',
      previous: false,
      deleted: false,
      fields_enabled: true,
      store_relationship_display_temp_id: uuidv4(),
      completion_date_error: undefined,
      created_at: new Date(),
      store_relationship_display_files_deleted: [],
    };

    setStoreRelationshipDisplays(prevStoreRelationshipDisplays => [
      ...prevStoreRelationshipDisplays,
      newStoreRelationshipDisplay,
    ]);
  }

  function handleDeleteRowTable(
    deletedRowStoreRelationshipDisplay: IStoreRelationshipDisplay,
  ): void {
    api.delete(
      `storerelationshipdisplayfilesreceived/` +
        `storerelationshipdisplaytempid/` +
        `${deletedRowStoreRelationshipDisplay.store_relationship_display_temp_id}`,
    );

    setStoreRelationshipDisplays(
      storeRelationshipDisplays.filter(
        storeRelDisplay =>
          storeRelDisplay.store_relationship_display_temp_id !==
          deletedRowStoreRelationshipDisplay.store_relationship_display_temp_id,
      ),
    );
  }

  async function handleRowCompletionDateChange(
    temp_id: string,
    fieldValue: string,
  ): Promise<void> {
    const completionDate = moment(fieldValue, 'DD/MM/YYYY').toDate();

    setStoreRelationshipDisplays(
      storeRelationshipDisplays.map(storeRelDisplay => {
        if (storeRelDisplay.store_relationship_display_temp_id === temp_id) {
          storeRelDisplay.completion_date = completionDate;
        }

        return storeRelDisplay;
      }),
    );

    if (
      !moment(fieldValue, 'DD/MM/YYYY').isValid() ||
      completionDate.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0)
    ) {
      setStoreRelationshipDisplays(
        storeRelationshipDisplays.map(storeRelDisplay => {
          if (storeRelDisplay.store_relationship_display_temp_id === temp_id) {
            storeRelDisplay.completion_date_error = 'Data inválida';
          }

          return storeRelDisplay;
        }),
      );
    } else {
      setStoreRelationshipDisplays(
        storeRelationshipDisplays.map(storeRelDisplay => {
          if (storeRelDisplay.store_relationship_display_temp_id === temp_id) {
            storeRelDisplay.completion_date_error = undefined;
          }

          return storeRelDisplay;
        }),
      );
    }
  }

  async function handleRowDisplayChange(
    temp_id: string,
    fieldValue: string,
  ): Promise<void> {
    setStoreRelationshipDisplays(
      storeRelationshipDisplays.map(storeRelDisplay => {
        if (storeRelDisplay.store_relationship_display_temp_id === temp_id) {
          storeRelDisplay.display_id = fieldValue;
        }
        return storeRelDisplay;
      }),
    );
  }

  async function handleRowNoteChange(
    temp_id: string,
    fieldValue: string,
  ): Promise<void> {
    setStoreRelationshipDisplays(
      storeRelationshipDisplays.map(storeRelDisplay => {
        if (storeRelDisplay.store_relationship_display_temp_id === temp_id) {
          storeRelDisplay.note = fieldValue;
        }
        return storeRelDisplay;
      }),
    );
  }

  function handleRowFieldsDisabled(temp_id: string): void {
    setStoreRelationshipDisplays(
      storeRelationshipDisplays.map(storeRelDisplay => {
        if (storeRelDisplay.store_relationship_display_temp_id === temp_id) {
          storeRelDisplay.fields_enabled = false;
        }

        return storeRelDisplay;
      }),
    );
  }

  useEffect(() => {
    if (confirmStoreRelationshipDisplayEnabled) {
      if (storeRelationshipDisplayEnableTempId) {
        if (storeRelationshipDisplayEnableTempId !== '') {
          setStoreRelationshipDisplays(
            storeRelationshipDisplays.map(storeRelDisplay => {
              if (
                storeRelDisplay.store_relationship_display_temp_id ===
                storeRelationshipDisplayEnableTempId
              ) {
                storeRelDisplay.fields_enabled = true;
              }

              return storeRelDisplay;
            }),
          );
          setStoreRelationshipDisplayEnableTempId('');
          setConfirmStoreRelationshipDisplayEnabled(false);
        }
      }
    }
  }, [
    confirmStoreRelationshipDisplayEnabled,
    storeRelationshipDisplayEnableTempId,
    storeRelationshipDisplays,
  ]);

  useEffect(() => {
    if (confirmStoreRelationshipDisplayDelete) {
      if (storeRelationshipDisplayDeleteTempId) {
        if (storeRelationshipDisplayDeleteTempId !== '') {
          setStoreRelationshipDisplays(
            storeRelationshipDisplays.map(storeRelDisplay => {
              if (
                storeRelDisplay.store_relationship_display_temp_id ===
                storeRelationshipDisplayDeleteTempId
              ) {
                storeRelDisplay.deleted = true;
                storeRelDisplay.store_relationship_record_reason =
                  reasonDeleteStoreRelationshipDisplay;

                api.delete(
                  `storerelationshipdisplayfilesreceived/` +
                    `storerelationshipdisplaytempid/` +
                    `${storeRelDisplay.store_relationship_display_temp_id}`,
                );
              }

              return storeRelDisplay;
            }),
          );

          setStoreRelationshipDisplayDeleteTempId('');
          setConfirmStoreRelationshipDisplayDelete(false);
          setReasonDeleteStoreRelationshipDisplay('');
        }
      }
    }
  }, [
    confirmStoreRelationshipDisplayDelete,
    storeRelationshipDisplayDeleteTempId,
    storeRelationshipDisplays,
    reasonDeleteStoreRelationshipDisplay,
  ]);

  useEffect(() => {
    if (confirmStoreRelationshipDisplayFileDelete) {
      if (storeRelationshipDisplayFileDeleteId) {
        if (storeRelationshipDisplayFileDeleteId !== '') {
          setPreviousStoreRelationshipDisplayFiles(
            previousStoreRelationshipDisplayFiles.map(
              previousStoreRelationshipDisplayFile => {
                if (
                  previousStoreRelationshipDisplayFile.id ===
                  storeRelationshipDisplayFileDeleteId
                ) {
                  previousStoreRelationshipDisplayFile.deleted = true;

                  setStoreRelationshipDisplays(
                    storeRelationshipDisplays.map(storeRelDisplay => {
                      if (
                        storeRelDisplay.id ===
                        previousStoreRelationshipDisplayFile
                          .store_relationship_display.id
                      ) {
                        storeRelDisplay.store_relationship_display_files_deleted.push(
                          {
                            id: storeRelationshipDisplayFileDeleteId,
                          },
                        );
                      }

                      return storeRelDisplay;
                    }),
                  );
                }

                return previousStoreRelationshipDisplayFile;
              },
            ),
          );

          setStoreRelationshipDisplayFileDeleteId('');
          setConfirmStoreRelationshipDisplayFileDelete(false);
        }
      }
    }
  }, [
    confirmStoreRelationshipDisplayFileDelete,
    storeRelationshipDisplayFileDeleteId,
    previousStoreRelationshipDisplayFiles,
    storeRelationshipDisplays,
  ]);

  function getDisplayLabel(display_id: string): string {
    if (displays) {
      if (displays.length > 0) {
        const display = displays.find(disp => disp.id === display_id);

        if (display) {
          return `(${display.code}) - ${display.name}`;
        }
      }
    }

    return '';
  }

  function handleRowEnableFieldsButtons(
    rowStoreRelationshipDisplay: IStoreRelationshipDisplay,
  ): React.ReactNode {
    if (rowStoreRelationshipDisplay.fields_enabled) {
      return (
        <UnlockButton
          iconSize={20}
          type="button"
          onClick={() => {
            handleRowFieldsDisabled(
              rowStoreRelationshipDisplay.store_relationship_display_temp_id,
            );
          }}
        />
      );
    }

    return (
      <LockButton
        iconSize={20}
        type="button"
        onClick={() => {
          setStoreRelationshipDisplayEnableTempId(
            rowStoreRelationshipDisplay.store_relationship_display_temp_id,
          );
          setIsOpenWarning(true);
        }}
      />
    );
  }

  function handleRowDeletePreviousRowButton(
    rowStoreRelationshipDisplay: IStoreRelationshipDisplay,
  ): React.ReactNode {
    if (rowStoreRelationshipDisplay.record_type.toUpperCase() === 'MANUAL') {
      return (
        <DeletePreviousButton
          iconSize={20}
          marginTop="0.8rem"
          marginLeft="1rem"
          type="button"
          onClick={() => {
            setStoreRelationshipDisplayDeleteTempId(
              rowStoreRelationshipDisplay.store_relationship_display_temp_id,
            );
            setTitleDeleteStoreRelationshipDisplay(
              `Deseja realmente excluir o display previamente registrado?`,
            );
            setSubTitleDeleteStoreRelationshipDisplay(
              'A exclusão apenas será efetivada ao salvar o registro!',
            );
            setIsOpenDeleteStoreRelationshipDisplay(true);
          }}
        />
      );
    }

    return <></>;
  }

  function handleTableRowButtons(
    rowStoreRelationshipDisplay: IStoreRelationshipDisplay,
  ): React.ReactNode {
    if (rowStoreRelationshipDisplay.previous) {
      return (
        <>
          {handleRowEnableFieldsButtons(rowStoreRelationshipDisplay)}
          {handleRowDeletePreviousRowButton(rowStoreRelationshipDisplay)}
        </>
      );
    }

    return (
      <DeleteRowButton
        type="button"
        onClick={() => {
          handleDeleteRowTable(rowStoreRelationshipDisplay);
        }}
      />
    );
  }

  function handleFileImageIcon(fileUrl: string): React.ReactNode {
    const fileMimeType = mime.getType(fileUrl);

    if (fileMimeType === 'audio/mp3' || fileMimeType === 'audio/mpeg') {
      return <AudioIcon iconSize={50} />;
    }

    if (fileMimeType === 'application/pdf') {
      return <PdfIcon iconSize={50} />;
    }

    if (
      fileMimeType ===
        'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
      fileMimeType === 'application/vnd.oasis.opendocument.presentation'
    ) {
      return <PresentationIcon iconSize={50} />;
    }

    if (
      fileMimeType ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      fileMimeType === 'application/vnd.oasis.opendocument.spreadsheet'
    ) {
      return <SpreadsheetIcon iconSize={50} />;
    }

    if (fileMimeType === 'text/plain') {
      return <TextIcon iconSize={50} />;
    }

    if (
      fileMimeType === 'image/gif' ||
      fileMimeType === 'video/quicktime' ||
      fileMimeType === 'video/mp4'
    ) {
      return <VideoIcon iconSize={40} />;
    }

    if (
      fileMimeType ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      fileMimeType === 'application/vnd.oasis.opendocument.text'
    ) {
      return <WordIcon iconSize={50} />;
    }

    return (
      <FileImageWrapper>
        <FileImage src={fileUrl} />
      </FileImageWrapper>
    );
  }

  function handleRowPreviousFile(
    receivedPreviousStoreRelationshipDisplayFile: IPreviousStoreRelationshipDisplayFile,
    previous: boolean,
    fields_enabled: boolean,
  ): React.ReactNode {
    if (!receivedPreviousStoreRelationshipDisplayFile.deleted) {
      return (
        <div style={{ display: 'flex', marginBottom: '20px' }}>
          <div style={{ width: '20%' }}>
            {handleFileImageIcon(
              receivedPreviousStoreRelationshipDisplayFile.url,
            )}
          </div>

          <div
            style={{
              width: '60%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <FileInfoName>
              {receivedPreviousStoreRelationshipDisplayFile.name}
            </FileInfoName>
            <FileInfoFileSize>
              {receivedPreviousStoreRelationshipDisplayFile.size}
            </FileInfoFileSize>
            <FileInfoFileTypeDescription>
              {
                receivedPreviousStoreRelationshipDisplayFile.file_type_description
              }
            </FileInfoFileTypeDescription>
          </div>

          <div
            style={{
              width: '20%',
              display: 'flex',
              justifyContent: 'right',
              alignItems: 'center',
            }}
          >
            {receivedPreviousStoreRelationshipDisplayFile.url && (
              <FileUrlIcon>
                <a
                  href={receivedPreviousStoreRelationshipDisplayFile.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLink size={20} />
                </a>
              </FileUrlIcon>
            )}

            {!!receivedPreviousStoreRelationshipDisplayFile.url && (
              <DeletePreviousButton
                iconSize={24}
                type="button"
                disabled={previous && !fields_enabled}
                isDisabled={!fields_enabled}
                onClick={() => {
                  setStoreRelationshipDisplayFileDeleteId(
                    receivedPreviousStoreRelationshipDisplayFile.id,
                  );
                  setTitleDeleteStoreRelationshipDisplayFile(
                    `Deseja realmente excluir o arquivo ` +
                      `${receivedPreviousStoreRelationshipDisplayFile.name} ` +
                      `previamente registrado no display de código ` +
                      `${receivedPreviousStoreRelationshipDisplayFile.store_relationship_display.code}?`,
                  );
                  setIsOpenDeleteStoreRelationshipDisplayFile(true);
                }}
              />
            )}
          </div>
        </div>
      );
    }

    return <></>;
  }

  function handleRowPreviousFiles(
    receivedStoreRelationshipDisplay: IStoreRelationshipDisplay,
  ): React.ReactNode {
    if (receivedStoreRelationshipDisplay) {
      if (receivedStoreRelationshipDisplay.id) {
        if (receivedStoreRelationshipDisplay.id !== '') {
          if (previousStoreRelationshipDisplayFiles) {
            if (previousStoreRelationshipDisplayFiles.length > 0) {
              const rowStoreRelationshipDisplayFiles =
                previousStoreRelationshipDisplayFiles.filter(
                  prevStoreRelationshipDisplayFile =>
                    prevStoreRelationshipDisplayFile.store_relationship_display_id ===
                    receivedStoreRelationshipDisplay.id,
                );

              if (rowStoreRelationshipDisplayFiles) {
                if (rowStoreRelationshipDisplayFiles.length > 0) {
                  return (
                    <ItemUploadWrapper
                      disabled={
                        receivedStoreRelationshipDisplay.previous &&
                        !receivedStoreRelationshipDisplay.fields_enabled
                      }
                    >
                      <ItemUploadLabel>ANEXOS</ItemUploadLabel>
                      <ItemPreviousFilesWrapper>
                        <FileMainContainer>
                          {rowStoreRelationshipDisplayFiles.map(
                            rowStoreRelationshipDisplayFile =>
                              handleRowPreviousFile(
                                rowStoreRelationshipDisplayFile,
                                receivedStoreRelationshipDisplay.previous,
                                receivedStoreRelationshipDisplay.fields_enabled,
                              ),
                          )}
                        </FileMainContainer>
                      </ItemPreviousFilesWrapper>
                    </ItemUploadWrapper>
                  );
                }
              }
            }
          }
        }
      }
    }

    return <></>;
  }

  function handleTableRow(
    rowStoreRelationshipDisplay: IStoreRelationshipDisplay,
  ): React.ReactNode {
    if (!rowStoreRelationshipDisplay.deleted) {
      return (
        <tr
          key={rowStoreRelationshipDisplay.store_relationship_display_temp_id}
        >
          <ItemSeparator />

          <ItemContent>
            <ItemWrapper>
              <TableDateInput
                type="text"
                labelText="Data Conclusão"
                upperCaseLabel
                defaultValue={rowStoreRelationshipDisplay.completion_date.toLocaleDateString()}
                error={rowStoreRelationshipDisplay.completion_date_error}
                disabled={
                  rowStoreRelationshipDisplay.previous &&
                  !rowStoreRelationshipDisplay.fields_enabled
                }
                onChange={e => {
                  handleRowCompletionDateChange(
                    rowStoreRelationshipDisplay.store_relationship_display_temp_id,
                    e.target.value,
                  );
                }}
              />
            </ItemWrapper>

            <ItemWrapper>
              <TableSelect
                label="Tipo"
                upperCaseLabel
                disabled={
                  (rowStoreRelationshipDisplay.previous &&
                    !rowStoreRelationshipDisplay.fields_enabled) ||
                  (rowStoreRelationshipDisplay.previous &&
                    rowStoreRelationshipDisplay.record_type.toUpperCase() ===
                      'PROJETO')
                }
                options={displayOptions}
                loading={loadingDisplays}
                defaultValue={{
                  value: rowStoreRelationshipDisplay.display_id,
                  label: getDisplayLabel(
                    rowStoreRelationshipDisplay.display_id,
                  ),
                }}
                onSet={(e: any) => {
                  handleRowDisplayChange(
                    rowStoreRelationshipDisplay.store_relationship_display_temp_id,
                    e.value,
                  );
                }}
              />
            </ItemWrapper>
          </ItemContent>

          <ItemContent>
            <ItemWrapper>
              <TableTextArea
                labelText="Observação"
                upperCaseLabel
                defaultValue={rowStoreRelationshipDisplay.note}
                maxLength={2000}
                disabled={
                  rowStoreRelationshipDisplay.previous &&
                  !rowStoreRelationshipDisplay.fields_enabled
                }
                onChange={e => {
                  handleRowNoteChange(
                    rowStoreRelationshipDisplay.store_relationship_display_temp_id,
                    e.target.value,
                  );
                }}
              />
            </ItemWrapper>
          </ItemContent>

          {handleRowPreviousFiles(rowStoreRelationshipDisplay)}

          <ItemUploadWrapper
            disabled={
              rowStoreRelationshipDisplay.previous &&
              !rowStoreRelationshipDisplay.fields_enabled
            }
          >
            <UploadStoreRelationshipDisplayFiles
              type="display_rel_loja"
              store_relationship_display_temp_id={
                rowStoreRelationshipDisplay.store_relationship_display_temp_id
              }
              user_id={user.id}
              isDisabled={
                rowStoreRelationshipDisplay.previous &&
                !rowStoreRelationshipDisplay.fields_enabled
              }
            />
          </ItemUploadWrapper>

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '10px 5px 10px 10px',
            }}
          >
            {handleTableRowButtons(rowStoreRelationshipDisplay)}
          </div>
        </tr>
      );
    }

    return <></>;
  }

  function handleTable(): React.ReactNode {
    if (storeRelationshipDisplays) {
      if (storeRelationshipDisplays.length > 0) {
        return (
          <Table>
            {loadingDisplays && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <PropagateLoader color="#276c8d" />
              </div>
            )}
            {storeRelationshipDisplays?.map(storeRelationshipDisplay =>
              handleTableRow(storeRelationshipDisplay),
            )}
          </Table>
        );
      }
    }

    return <></>;
  }

  return (
    <Container>
      <BreadCrumb
        main="Lojas"
        path="/storerelationships"
        firstChildPath="/storerelationships/procedure1"
        firstChildState={client}
        firstChild="Atualização Registro"
        secondChild="Atualização Displays"
        mainIcon={FaStore}
        firstChildIcon={FaStore}
        secondChildIcon={FaBox}
        mainColored
        firstChildColored
      />

      <StoreRelationshipInfo
        erp_rep_code={state?.erp_rep_code ? state.erp_rep_code : ''}
        erp_client_code={state?.erp_client_code ? state.erp_client_code : ''}
        store_relationship_id={state?.id}
      />

      <form onSubmit={handleSubmit(submitForm)}>
        <LabelContainer>
          <Label>Displays</Label>
        </LabelContainer>

        <Content maxWidth="680px">{handleTable()}</Content>

        <ContentAddRow maxWidth="680px">
          <AddRowButton type="button" onClick={() => handleAddRowTable()} />
        </ContentAddRow>

        <ButtonContainer>
          <SaveButton
            type="button"
            onClick={() => {
              onValidateConfirm();
            }}
          />

          <ExitButton
            marginLeft="1rem"
            type="button"
            onClick={() => setIsOpenExit(true)}
          />
        </ButtonContainer>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={onConfirmProcedure}
        title={titleConfirmProcedure}
        subTitle=""
        opConfirm="Sim"
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />

      <ConfirmWarningDialog
        isOpen={isOpenDeleteStoreRelationshipDisplayFile}
        setIsOpen={setIsOpenDeleteStoreRelationshipDisplayFile}
        onConfirm={onDeleteStoreRelationshipDisplayFile}
        title={titleDeleteStoreRelationshipDisplayFile}
        subTitle="A exclusão apenas será efetivada ao salvar o registro de loja!"
        opConfirm="Sim, excluir"
      />

      <ConfirmWarningDialog
        isOpen={isOpenWarning}
        setIsOpen={setIsOpenWarning}
        onConfirm={onConfirmWarning}
        title="Deseja realmente alterar o display previamente registrado?"
        opConfirm="Sim, alterar"
      />

      <DeleteModal
        isOpen={isOpenDeleteStoreRelationshipDisplay}
        onDelete={onDeleteStoreRelationshipDisplay}
        title={titleDeleteStoreRelationshipDisplay}
        subTitle={subTitleDeleteStoreRelationshipDisplay}
        setIsOpen={setIsOpenDeleteStoreRelationshipDisplay}
        setReason={setReasonDeleteStoreRelationshipDisplay}
      />

      <NoticeDialog
        isOpen={isOpenNotice}
        type="error"
        title={titleNotice}
        subTitle={subTitleNotice}
        setIsOpen={setIsOpenNotice}
      />
    </Container>
  );
};

export default ProcedureDisplays;
