import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

export interface IExitProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  marginLeft?: string;
}

const Exit: React.FC<IExitProps> = ({ label, ...rest }) => {
  return (
    <Container {...rest}>{label !== undefined ? label : 'Sair'}</Container>
  );
};

export default Exit;
