import React from 'react';

import { FaWrench, FaTrashAlt, FaMobileAlt, FaCog } from 'react-icons/fa';

export default {
  'Alteração Manual de Solicitação': {
    icon: <FaWrench size={30} />,
    link: '/management/projectediting',
    backgroundColor: '#747474',
  },
  'Exclusão de Solicitação': {
    icon: <FaTrashAlt size={30} />,
    link: '/management/projectdeletion',
    backgroundColor: '#747474',
  },
  'Acessos Aplicativos Mobile': {
    icon: <FaMobileAlt size={30} />,
    link: '/management/mobileaccesses',
    backgroundColor: '#747474',
  },
  'Configurações Gerais': {
    icon: <FaCog size={30} />,
    link: '/management/generalsettings',
    backgroundColor: '#747474',
  },
};
