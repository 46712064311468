import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { FaProjectDiagram, FaCalendarAlt } from 'react-icons/fa';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { string, number } from 'yup';
import { v4 as uuidv4 } from 'uuid';

import BreadCrumb from '~/components/BreadCrumb';
import NewProjectInfo from '~/components/ProjectInfo/New';
import Select from '~/components/Select';
import DefaultInput from '~/components/Inputs/Default';
import PhoneInput from '~/components/Inputs/Phone';
import DateInput from '~/components/Inputs/Date';
import WholeNumberInput from '~/components/Inputs/WholeNumber';
import TextArea from '~/components/TextArea';
import UploadRequestFiles from '~/components/UploadRequestFiles';
import UploadFiles from '~/components/UploadFiles';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  Content,
  ContentSplit,
  Wrapper,
  LabelContainer,
  Label,
  BorderContent,
  FlexWrapper,
  ButtonContainer,
} from './styles';

interface IClient {
  code: string;
  name: string;
  company_name: string;
  document: string;
  address: string;
  city: string;
  state: string;
}

interface IFormProcedure1 {
  type: string;
  price: number;
  shipping_price: number;
  rep_as_client: boolean;
  erp_client_code: string;
  erp_rep_code: string;
  note: string;
  client_contact_name: string;
  client_contact_phone: string;
  client_contact_email: string;
  estimated_event_participants_number: number;
  event_date: Date;
  event: {
    value: string;
  };
  erp_invoice_client_code: {
    value: string;
  };
  user_inclusion_id: string;
  user_update_id: string;
  record_description: string;
  record_note: string;
  project_temp_id: string;
  project_request_temp_id: string;
}

interface ILocation {
  erp_client_code?: string;
  erp_client_name?: string;
  erp_client_company_name?: string;
  erp_client_document?: string;
  erp_client_address?: string;
  erp_client_city?: string;
  erp_client_state?: string;
  erp_rep_code: string;
}

const schema = Yup.object().shape({
  event: Yup.object().shape({
    value: Yup.string().required('Campo obrigatório'),
  }),
  erp_invoice_client_code: Yup.object().shape({
    value: Yup.string().required('Campo obrigatório'),
  }),
  client_contact_name: Yup.string().required('Campo obrigatório'),
  client_contact_phone: Yup.string()
    .required('Campo obrigatório')
    .min(14, 'Telefone/celular inválido')
    .max(15, 'Telefone/celular inválido'),
  client_contact_email: Yup.string()
    .required('Campo obrigatório')
    .email('E-mail inválido'),
  event_date: Yup.date()
    .min(
      new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 1),
      'Data inválida',
    )
    .max(
      new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 1825),
      'Data inválida',
    )
    .typeError('Campo obrigatório'),
  estimated_event_participants_number: Yup.string().when((value: string) => {
    if (Number.isNaN(Number(parseFloat(value)))) {
      return string().required('Quantidade inválida');
    }
    if (parseFloat(value) <= 0) {
      return number()
        .min(1, 'Quantidade inválida')
        .required('Quantidade inválida')
        .typeError('Quantidade inválida');
    }
    return string().notRequired();
  }),
});

const Procedure1: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [events, setEvents] = useState<
    {
      id: string;
      name: string;
    }[]
  >();
  const [clients, setClients] = useState<IClient[]>();
  const [loadingEvents, setLoadingEvents] = useState<boolean>(true);
  const [loadingClients, setLoadingClients] = useState<boolean>(true);
  const [selectedClient, setSelectedClient] = useState<IClient>();
  const [projectTempFilled, setProjectTempFilled] = useState(false);
  const [projectTemp, setProjectTemp] = useState('');
  const [projectRequestTempFilled, setProjectRequestTempFilled] =
    useState(false);
  const [projectRequestTemp, setProjectRequestTemp] = useState('');
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [isOpenExit, setIsOpenExit] = useState(false);

  const { handleSubmit, register, getValues, setValue, control, errors } =
    useForm<IFormProcedure1>({ resolver: yupResolver(schema) });

  useEffect(() => {
    const checkEnabledFunctionality = async () => {
      await api.get('/generalsettings').then(response => {
        if (response.data && !response.data.new_event_req_enabled) {
          history.push('/');
        }
      });
    };

    checkEnabledFunctionality();
  }, [history]);

  useEffect(() => {
    if (clients) {
      if (clients.length === 0 && state) {
        if (state.erp_client_code !== undefined) {
          setValue('erp_invoice_client_code', {
            value: state.erp_client_code,
          });
        }

        setSelectedClient({
          code:
            state.erp_client_code !== undefined ? state.erp_client_code : '',
          name:
            state.erp_client_name !== undefined ? state.erp_client_name : '',
          company_name:
            state.erp_client_company_name !== undefined
              ? state.erp_client_company_name
              : '',
          document:
            state.erp_client_document !== undefined
              ? state.erp_client_document
              : '',
          address:
            state.erp_client_address !== undefined
              ? state.erp_client_address
              : '',
          city:
            state.erp_client_city !== undefined ? state.erp_client_city : '',
          state:
            state.erp_client_state !== undefined ? state.erp_client_state : '',
        });
      }
    }
  }, [clients, state, setValue]);

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Cliente não selecionado',
      });

      history.push('/projects/events');
    } else if (
      user.user_type.type !== 'REP' &&
      user.user_type.type !== 'ADM' &&
      user.user_type.type !== 'MK1' &&
      user.user_type.type !== 'MK2' &&
      user.user_type.type !== 'MAX' &&
      user.user_type.type !== 'GCM'
    ) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Tipo de usuário não permitido',
      });

      history.push('/projects/events');
    } else {
      register('project_temp_id');
      register('project_request_temp_id');
      register('type');
      register('rep_as_client');
      register('erp_client_code');
      register('erp_rep_code');
      register('erp_invoice_client_code');
      register('user_update_id');
      register('user_inclusion_id');
      register('record_description');
      register('record_note');

      setValue('type', 'EVENTO');
      setValue('rep_as_client', false);
      setValue('erp_client_code', state.erp_client_code);
      setValue('erp_rep_code', state.erp_rep_code);
      setValue('user_update_id', user.id);
      setValue('user_inclusion_id', user.id);
      setValue('record_description', 'Abertura de projeto');

      api.get('/items/events/valid').then(response => {
        setEvents(response.data);
        setLoadingEvents(false);
      });

      api
        .get(`/clients/clientgroup?code=${state.erp_client_code}`)
        .then(response => {
          setClients(response.data);
          setLoadingClients(false);
        });

      if (!projectTempFilled) {
        setProjectTempFilled(true);
        setProjectTemp(uuidv4());
      }

      if (!projectRequestTempFilled) {
        setProjectRequestTempFilled(true);
        setProjectRequestTemp(uuidv4());
      }
    }
  }, [
    addToast,
    history,
    state,
    user,
    projectTempFilled,
    projectRequestTempFilled,
    register,
    setValue,
  ]);

  const eventOptions = events?.map(event => ({
    value: event.id,
    label: event.name,
  }));

  const clientOptions = clients?.map(client => ({
    value: client.code,
    label: `(${client.code} - ${client.document}) - ${client.company_name}`,
  }));

  useEffect(() => {
    setValue('project_temp_id', projectTemp);
  }, [projectTemp, setValue]);

  useEffect(() => {
    setValue('project_request_temp_id', projectRequestTemp);
  }, [projectRequestTemp, setValue]);

  const submitForm = useCallback(
    async (data: IFormProcedure1) => {
      try {
        await api.post('/projects', data);

        addToast({
          type: 'success',
          title: 'Projeto iniciado!',
          description: 'Abertura de projeto realizado com sucesso.',
        });

        history.push('/projects/events');
      } catch (error: any) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [addToast, history],
  );

  function onConfirmProcedure(): void {
    const estEventPartNumberValue = getValues(
      'estimated_event_participants_number',
    );
    const noteValue = getValues('note');

    let estEventPartNumberConv = estEventPartNumberValue.toString();
    estEventPartNumberConv = estEventPartNumberConv.replaceAll('.', '');
    estEventPartNumberConv = estEventPartNumberConv.replaceAll(',', '.');

    if (!Number.isNaN(Number(parseFloat(estEventPartNumberConv)))) {
      setValue(
        'estimated_event_participants_number',
        parseFloat(estEventPartNumberConv),
      );
    }

    setValue('record_note', noteValue);

    handleSubmit(submitForm)();
  }

  const onExit = useCallback(() => {
    api.delete(`projectfilesreceived/projecttempid/${projectTemp}`);
    api.delete(
      `projectrequestfilesreceived/projectrequesttempid/${projectRequestTemp}`,
    );

    history.push('/projects/events');
  }, [history, projectTemp, projectRequestTemp]);

  function handleInvoiceClient(): React.ReactNode | null {
    if (clients) {
      if (clients.length > 0) {
        return (
          <Content>
            <Wrapper style={{ width: '50%' }}>
              <Select
                label="Cliente Faturamento"
                options={clientOptions}
                loading={loadingClients}
                error={errors?.erp_invoice_client_code?.value?.message}
                onSet={(e: any) => {
                  setValue(
                    'erp_invoice_client_code',
                    {
                      value: e.value,
                    },
                    {
                      shouldValidate: true,
                    },
                  );

                  const selClient = clients.find(cli => cli.code === e.value);

                  if (selClient) {
                    setSelectedClient({
                      code: selClient.code,
                      name: selClient.name,
                      company_name: selClient.company_name,
                      document: selClient.document,
                      address: selClient.address,
                      city: selClient.city,
                      state: selClient.state,
                    });
                  }
                }}
              />
            </Wrapper>
          </Content>
        );
      }

      return (
        <Content>
          <Wrapper style={{ width: '70%' }}>
            <DefaultInput
              name="erp_invoice_client_code_def"
              labelText="Cliente Faturamento"
              type="text"
              value={`(${selectedClient?.code}) - ${selectedClient?.company_name}`}
              disabled
            />
          </Wrapper>
        </Content>
      );
    }

    return null;
  }

  function handleFiles(): React.ReactNode {
    if (user.user_type.type !== 'REP') {
      return (
        <Content>
          <UploadFiles
            type="anexo"
            project_temp_id={projectTemp}
            project_type="EVENTO"
            sequence={1}
            user_id={user.id}
          />
        </Content>
      );
    }

    return <></>;
  }

  return (
    <Container>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChildPath="/projects/events"
        firstChild="Eventos"
        secondChild="Nova Solicitação"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaCalendarAlt}
        secondChildIcon={FaCalendarAlt}
        mainColored
        firstChildColored
      />

      <NewProjectInfo
        rep_as_client={false}
        erp_rep_code={state?.erp_rep_code}
        erp_client_code={state?.erp_client_code}
      />

      <form onSubmit={handleSubmit(submitForm)}>
        <Content>
          <Wrapper style={{ width: '50%' }}>
            <Controller
              name="event"
              control={control}
              options={eventOptions}
              label="Tipo de Evento"
              error={errors?.event?.value?.message}
              as={Select}
              loading={loadingEvents}
            />
          </Wrapper>
        </Content>

        {handleInvoiceClient()}

        <LabelContainer>
          <Label>Informações Cliente Faturamento</Label>
        </LabelContainer>

        <BorderContent>
          <FlexWrapper>
            <Wrapper style={{ width: '60%', marginRight: '1rem' }}>
              <DefaultInput
                name="erp_invoice_client_name"
                labelFor="erp_invoice_client_name"
                labelText="Razão Social"
                type="text"
                value={selectedClient?.company_name}
                disabled
              />
            </Wrapper>

            <Wrapper style={{ width: '40%' }}>
              <DefaultInput
                name="erp_invoice_client_document"
                labelFor="erp_invoice_client_document"
                labelText="CNPJ"
                type="text"
                value={selectedClient?.document}
                disabled
              />
            </Wrapper>
          </FlexWrapper>

          <FlexWrapper>
            <Wrapper style={{ width: '50%', marginRight: '1rem' }}>
              <DefaultInput
                name="erp_invoice_client_address"
                labelFor="erp_invoice_client_address"
                labelText="Endereço"
                type="text"
                value={selectedClient?.address}
                disabled
              />
            </Wrapper>

            <Wrapper style={{ width: '35%', marginRight: '1rem' }}>
              <DefaultInput
                name="erp_invoice_client_city"
                labelFor="erp_invoice_client_city"
                labelText="Cidade"
                type="text"
                value={selectedClient?.city}
                disabled
              />
            </Wrapper>

            <Wrapper style={{ width: '15%' }}>
              <DefaultInput
                name="erp_invoice_client_state"
                labelFor="erp_invoice_client_state"
                labelText="Estado"
                type="text"
                value={selectedClient?.state}
                disabled
              />
            </Wrapper>
          </FlexWrapper>
        </BorderContent>

        <LabelContainer>
          <Label>Contato da Loja</Label>
        </LabelContainer>

        <BorderContent>
          <FlexWrapper>
            <Wrapper style={{ width: '60%', marginRight: '1rem' }}>
              <DefaultInput
                name="client_contact_name"
                labelFor="client_contact_name"
                labelText="Nome"
                type="text"
                error={errors?.client_contact_name?.message}
                ref={register}
                maxLength={60}
              />
            </Wrapper>

            <Wrapper style={{ width: '40%' }}>
              <PhoneInput
                name="client_contact_phone"
                labelFor="client_contact_phone"
                labelText="Telefone"
                type="text"
                error={errors?.client_contact_phone?.message}
                ref={register}
              />
            </Wrapper>
          </FlexWrapper>

          <FlexWrapper>
            <Wrapper style={{ width: '100%' }}>
              <DefaultInput
                name="client_contact_email"
                labelFor="client_contact_email"
                labelText="E-mail"
                type="text"
                error={errors?.client_contact_email?.message}
                ref={register}
                maxLength={60}
              />
            </Wrapper>
          </FlexWrapper>
        </BorderContent>

        <ContentSplit>
          <Wrapper style={{ width: '50%', marginRight: '1rem' }}>
            <DateInput
              name="event_date"
              labelFor="event_date"
              labelText="Data Evento"
              type="date"
              error={errors?.event_date?.message}
              ref={register}
            />
          </Wrapper>

          <Wrapper style={{ width: '50%' }}>
            <WholeNumberInput
              name="estimated_event_participants_number"
              labelFor="estimated_event_participants_number"
              labelText="Número Estimado de Participantes"
              defaultValue={0}
              error={errors?.estimated_event_participants_number?.message}
              ref={register}
            />
          </Wrapper>
        </ContentSplit>

        <Content>
          <Wrapper style={{ width: '100%' }}>
            <TextArea
              name="note"
              labelFor="note"
              labelText="Observação"
              error={errors?.note?.message}
              ref={register}
              maxLength={1000}
            />
          </Wrapper>
        </Content>

        <Content>
          <UploadRequestFiles
            type="requisicao"
            project_request_temp_id={projectRequestTemp}
            project_type="EVENTO"
            sequence={1}
            user_id={user.id}
          />
        </Content>

        {handleFiles()}

        <ButtonContainer>
          <SaveButton
            type="button"
            onClick={() => {
              setIsOpenConfirmProcedure(true);
            }}
          />

          <ExitButton
            marginLeft="1rem"
            type="button"
            onClick={() => setIsOpenExit(true)}
          />
        </ButtonContainer>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={onConfirmProcedure}
        title="Confirma abertura do projeto?"
        subTitle="O projeto será enviado para o próximo procedimento"
        opConfirm="Sim"
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />
    </Container>
  );
};

export default Procedure1;
