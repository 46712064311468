import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { FaProjectDiagram, FaBox } from 'react-icons/fa';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';

import BreadCrumb from '~/components/BreadCrumb';
import ProjectInfo from '~/components/ProjectInfo/Default';
import CurrencyInput from '~/components/Inputs/Currency';
import DefaultInput from '~/components/Inputs/Default';
import Toggle from '~/components/Toggle';
import TextArea from '~/components/TextArea';
import UploadCustomProjectFiles from '~/components/UploadCustomProjectFiles';
import UploadFiles from '~/components/UploadFiles';
import SaveButton from '~/components/Buttons/Save';
import RejectButton from '~/components/Buttons/Reject';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import RejectProjectModal from '~/components/Modals/RejectProject';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  Content,
  ContentSplit,
  Wrapper,
  ButtonContainer,
} from './styles';

interface IFormProcedure2 {
  type: string;
  code: string;
  unit_price: number;
  custom_project: boolean;
  user_update_id: string;
  validation_type: string;
  project_procedure_id: string;
  note_custom_project: string;
  record_description: string;
  record_note: string;
  record_reason: string;
  project_temp_id: string;
  project_custom_proj_temp_id: string;
}

interface ILocation {
  id: string;
  type: string;
  code: string;
  item_amount: number;
  erp_rep_code: string;
  status: string;
  project_procedure_id: string;
  project_procedure: {
    user_type: string;
  };
}

const schema = Yup.object().shape({
  unit_price: Yup.string().required('Campo obrigatório'),
});

const Procedure2: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [validationType, setValidationType] = useState('APROVADO');
  const [projectTempFilled, setProjectTempFilled] = useState(false);
  const [projectTemp, setProjectTemp] = useState('');
  const [projectCustomProjectTempFilled, setProjectCustomProjectTempFilled] =
    useState(false);
  const [projectCustomProjectTemp, setProjectCustomProjectTemp] = useState('');
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [titleConfirmProcedure, setTitleConfirmProcedure] = useState('');
  const [isOpenRejectProject, setIsOpenRejectProject] = useState(false);
  const [titleRejectProject, setTitleRejectProject] = useState('');
  const [subTitleRejectProject, setSubTitleRejectProject] = useState('');
  const [reasonRejectProject, setReasonRejectProject] = useState('');
  const [rejectionConfirmed, setRejectionConfirmed] = useState(false);
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [unitPrice, setUnitPrice] = useState(0);
  const [calculatedPrice, setCalculatedPrice] = useState(
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(0),
  );
  const [customProject, setCustomProject] = useState(false);

  const { handleSubmit, register, setValue, control, errors } =
    useForm<IFormProcedure2>({
      resolver: yupResolver(schema),
      defaultValues: {
        custom_project: false,
      },
    });

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto não localizado',
      });

      history.push('/projects/displays');
    } else if (
      user.user_type.type !== state.project_procedure.user_type &&
      user.user_type.type !== 'ADM' &&
      !(
        state.project_procedure.user_type === 'MK2' &&
        user.user_type.type === 'MK1'
      )
    ) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Tipo de usuário não permitido',
      });

      history.push('/projects/displays');
    } else if (
      state.project_procedure.user_type === 'REP' &&
      state.erp_rep_code !== user.erp_rep_code &&
      user.user_type.type !== 'ADM'
    ) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Usuário não permitido',
      });

      history.push('/projects/displays');
    } else if (state.status.toUpperCase() === 'CANCELADO') {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto Cancelado',
      });

      history.push('/projects/displays');
    } else if (state.status.toUpperCase() === 'FINALIZADO') {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto Finalizado',
      });

      history.push('/projects/displays');
    } else {
      register('validation_type');
      register('record_reason');
      register('project_temp_id');
      register('project_custom_proj_temp_id');
      register('type');
      register('code');
      register('record_description');
      register('user_update_id');
      register('project_procedure_id');

      setValue('type', 'DISPLAY');
      setValue('code', state.code);
      setValue('user_update_id', user.id);
      setValue('project_procedure_id', state.project_procedure_id);

      if (!projectTempFilled) {
        setProjectTempFilled(true);
        setProjectTemp(uuidv4());
      }

      if (!projectCustomProjectTempFilled) {
        setProjectCustomProjectTempFilled(true);
        setProjectCustomProjectTemp(uuidv4());
      }
    }
  }, [
    addToast,
    history,
    state,
    user,
    projectTempFilled,
    projectCustomProjectTempFilled,
    register,
    setValue,
  ]);

  useEffect(() => {
    setValue('validation_type', validationType);
    if (validationType === 'CANCELADO') {
      setRejectionConfirmed(true);
    }
  }, [validationType, setValue]);

  useEffect(() => {
    setValue('record_reason', reasonRejectProject);
  }, [reasonRejectProject, setValue, register]);

  useEffect(() => {
    setValue('project_temp_id', projectTemp);
  }, [projectTemp, setValue]);

  useEffect(() => {
    setValue('project_custom_proj_temp_id', projectCustomProjectTemp);
  }, [projectCustomProjectTemp, setValue]);

  useEffect(() => {
    setCalculatedPrice(
      new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(unitPrice * state.item_amount),
    );
  }, [state, unitPrice]);

  function onRejectProject(): void {
    setValidationType('CANCELADO');
  }

  const submitForm = useCallback(
    async (data: IFormProcedure2) => {
      try {
        await api.put(`/projects/${state.id}`, data);

        addToast({
          type: 'success',
          title: 'Projeto atualizado!',
          description: 'Projeto atualizado com sucesso.',
        });

        history.push('/projects/displays');
      } catch (error: any) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [addToast, history, state],
  );

  useEffect(() => {
    if (rejectionConfirmed) {
      setValue('record_description', 'Rejeição Marketing');
      handleSubmit(submitForm)();
    }
  }, [rejectionConfirmed, handleSubmit, submitForm, setValue]);

  function onConfirmProcedure(): void {
    if (!customProject) {
      setValue('note_custom_project', '');

      api.delete(
        `projectcustomprojectfilesreceived/` +
          `projectcustomprojecttempid/${projectCustomProjectTemp}`,
      );
    }

    setValue('unit_price', unitPrice);
    setValue(
      'record_description',
      `Aprovação Marketing, com inclusão de valor de ${calculatedPrice}`,
    );

    handleSubmit(submitForm)();
  }

  const onExit = useCallback(() => {
    api.delete(`projectfilesreceived/projecttempid/${projectTemp}`);
    api.delete(
      `projectcustomprojectfilesreceived/` +
        `projectcustomprojecttempid/${projectCustomProjectTemp}`,
    );

    history.push('/projects/displays');
  }, [history, projectTemp, projectCustomProjectTemp]);

  function handleUnitPriceChange(fieldValue: string): void {
    let unitPriceConv = fieldValue;
    let unitPriceValue = 0;

    unitPriceConv = unitPriceConv.replaceAll('R$', '');
    unitPriceConv = unitPriceConv.replaceAll('.', '');
    unitPriceConv = unitPriceConv.replaceAll(',', '.');

    if (!Number.isNaN(Number(parseFloat(unitPriceConv)))) {
      unitPriceValue = parseFloat(unitPriceConv);
    }

    setUnitPrice(unitPriceValue);
  }

  function handleCustomProjectData(): React.ReactNode | null {
    if (customProject) {
      return (
        <>
          <Content>
            <Wrapper style={{ width: '100%' }}>
              <TextArea
                name="note_custom_project"
                labelFor="note_custom_project"
                labelText="Observação Projeto Personalizado"
                ref={register}
                maxLength={1000}
              />
            </Wrapper>
          </Content>

          <Content>
            <UploadCustomProjectFiles
              type="customproject"
              project_custom_proj_temp_id={projectCustomProjectTemp}
              project_type="DISPLAY"
              sequence={2}
              user_id={user.id}
            />
          </Content>
        </>
      );
    }

    return null;
  }

  return (
    <Container>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChildPath="/projects/displays"
        firstChild="Displays"
        secondChild="Atualização Solicitação"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaBox}
        secondChildIcon={FaBox}
        mainColored
        firstChildColored
      />

      <ProjectInfo project_id={state.id} />

      <form onSubmit={handleSubmit(submitForm)}>
        <ContentSplit>
          <Wrapper style={{ width: '33%', marginRight: '1rem' }}>
            <DefaultInput
              name="item_amount"
              labelFor="item_amount"
              labelText="Quantidade"
              type="text"
              value={state?.item_amount}
              disabled
            />
          </Wrapper>

          <Wrapper style={{ width: '33%', marginRight: '1rem' }}>
            <CurrencyInput
              name="unit_price"
              labelFor="unit_price"
              labelText="Valor Unitário"
              defaultValue={0}
              error={errors?.unit_price?.message}
              onSet={e => {
                handleUnitPriceChange(e.target.value);
              }}
              ref={register}
            />
          </Wrapper>

          <Wrapper style={{ width: '33%' }}>
            <DefaultInput
              name="price"
              labelFor="price"
              labelText="Valor"
              type="text"
              value={calculatedPrice}
              disabled
            />
          </Wrapper>
        </ContentSplit>

        <Content>
          <Wrapper style={{ width: '100%' }}>
            <Controller
              render={props => (
                <Toggle
                  onChange={e => {
                    props.onChange(e);
                    setCustomProject(e);
                  }}
                  checked={props.value}
                  label="Projeto Personalizado"
                />
              )}
              name="custom_project"
              defaultValue
              control={control}
            />
          </Wrapper>
        </Content>

        {handleCustomProjectData()}

        <Content>
          <Wrapper style={{ width: '100%' }}>
            <TextArea
              name="record_note"
              labelFor="record_note"
              labelText="Observação"
              error={errors?.record_note?.message}
              ref={register}
              maxLength={1000}
            />
          </Wrapper>
        </Content>

        <Content>
          <UploadFiles
            type="anexo"
            project_temp_id={projectTemp}
            project_type="DISPLAY"
            sequence={2}
            user_id={user.id}
          />
        </Content>

        <ButtonContainer>
          <SaveButton
            label="Aprovar"
            type="button"
            onClick={() => {
              setIsOpenConfirmProcedure(true);
              setTitleConfirmProcedure(`Confirma aprovação do projeto ${state.code},
                no valor de ${calculatedPrice}?`);
            }}
          />

          <RejectButton
            marginLeft="1rem"
            type="button"
            onClick={() => {
              setIsOpenRejectProject(true);
              setTitleRejectProject(
                `Deseja realmente recusar o projeto ${state.code}?`,
              );
              setSubTitleRejectProject(
                'O projeto será cancelado e esta operação não poderá ser desfeita!',
              );
            }}
          />

          <ExitButton
            marginLeft="1rem"
            type="button"
            onClick={() => setIsOpenExit(true)}
          />
        </ButtonContainer>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={onConfirmProcedure}
        title={titleConfirmProcedure}
        subTitle="O projeto será enviado para o próximo procedimento"
        opConfirm="Sim"
      />

      <RejectProjectModal
        isOpen={isOpenRejectProject}
        onReject={onRejectProject}
        title={titleRejectProject}
        subTitle={subTitleRejectProject}
        setIsOpen={setIsOpenRejectProject}
        setReason={setReasonRejectProject}
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />
    </Container>
  );
};

export default Procedure2;
