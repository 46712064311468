import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FaProjectDiagram, FaFolder, FaLayerGroup } from 'react-icons/fa';

import BreadCrumb from '~/components/BreadCrumb';
import TableSelect from '~/components/TableSelect';
import TableWholeNumberInput from '~/components/TableInputs/WholeNumber';
import AddRowButton from '~/components/Buttons/AddRow';
import DeleteRowButton from '~/components/Buttons/DeleteRow';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';
import NoticeDialog from '~/components/Dialogs/Notice';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  Label,
  Content,
  ContentAddRow,
  Table,
  Separator,
  ItemContent,
  ItemWrapper,
  ButtonContainer,
} from './styles';

interface IProject {
  sequence: number;
  type: string;
  item_total_amount: number;
  erp_client_code: string;
  erp_rep_code: string;
  folder: {
    value: string;
  };
  shipping_type: string;
  first_request: string;
  user_inclusion_id: string;
  user_update_id: string;
  conflict_error: string;
}

interface IFormFolderBatches {
  projects: IProject[];
}

const Batches: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const [folders, setFolders] = useState<
    {
      id: string;
      name: string;
    }[]
  >();
  const [loadingFolders, setLoadingFolders] = useState<boolean>(true);
  const [clients, setClients] = useState<
    {
      name: string;
      code: string;
      document: string;
      rep_code: string;
    }[]
  >();
  const [loadingClients, setLoadingClients] = useState<boolean>(true);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [loadProjects, setloadProjects] = useState(false);
  const [projectsLoaded, setProjectsLoaded] = useState(false);
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [titleConfirmProcedure, setTitleConfirmProcedure] = useState('');
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [isOpenNotice, setIsOpenNotice] = useState(false);
  const [titleNotice, setTitleNotice] = useState('');
  const [subTitleNotice, setSubTitleNotice] = useState('');
  const projectSequence = useRef(1);

  const { handleSubmit, register, setValue } = useForm<IFormFolderBatches>();

  useEffect(() => {
    const checkEnabledFunctionality = async () => {
      await api.get('/generalsettings').then(response => {
        if (response.data && !response.data.new_folder_req_enabled) {
          history.push('/');
        }
      });
    };

    checkEnabledFunctionality();
  }, [history]);

  useEffect(() => {
    if (loadProjects) {
      if (clients && clients.length > 0) {
        const firstProject: IProject = {
          sequence: projectSequence.current,
          type: 'PASTA',
          item_total_amount: 0,
          erp_client_code: '',
          erp_rep_code: '',
          folder: { value: '' },
          shipping_type: '',
          first_request: '',
          user_inclusion_id: user.id,
          user_update_id: user.id,
          conflict_error: '',
        };

        setProjects([firstProject]);
        setloadProjects(false);
        setProjectsLoaded(true);
      }
    }
  }, [user, loadProjects, clients]);

  useEffect(() => {
    if (!projectsLoaded) {
      setloadProjects(true);
    }
  }, [projectsLoaded]);

  useEffect(() => {
    if (
      user.user_type.type !== 'ADM' &&
      user.user_type.type !== 'MK1' &&
      user.user_type.type !== 'MK2' &&
      user.user_type.type !== 'MAX'
    ) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Tipo de usuário não permitido',
      });

      history.push('/projects/folders');
    } else {
      register('projects');

      api.get('/items/folders/valid').then(response => {
        setFolders(response.data);
        setLoadingFolders(false);
      });

      api
        .get(
          `/clients/complete?user_type=${user.user_type.type}&erp_rep_code=${user.erp_rep_code}`,
        )
        .then(response => {
          setClients(response.data);
          setLoadingClients(false);
        });
    }
  }, [addToast, history, user, register, setValue]);

  const folderOptions = folders?.map(folder => ({
    value: folder.id,
    label: folder.name,
  }));

  const clientOptions = clients?.map(client => ({
    value: client.code,
    label: `(${client.document}) - ${client.name}`,
  }));

  const shippingTypeOptions: { value: string; label: string }[] = [
    { value: 'CIF', label: 'CIF' },
    { value: 'FOB', label: 'FOB' },
  ];

  const firstRequestOptions: { value: string; label: string }[] = [
    { value: 'Sim', label: 'Sim' },
    { value: 'Não', label: 'Não' },
  ];

  const submitForm = useCallback(
    async (data: IFormFolderBatches) => {
      try {
        await api.post('/projects/batches', data);

        addToast({
          type: 'success',
          title: 'Lotes registrados!',
          description: 'Lotes inseridos com sucesso.',
        });

        history.push('/projects/folders');
      } catch (error: any) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [addToast, history],
  );

  async function onValidateConfirmProcedure(): Promise<void> {
    const rowsEmptyClient = projects.filter(
      proj => proj.erp_client_code === undefined || proj.erp_client_code === '',
    );

    const rowsEmptyFolder = projects.filter(
      proj => proj.folder.value === undefined || proj.folder.value === '',
    );

    const rowsInvalidTotalAmount = projects.filter(
      proj => !(proj.item_total_amount > 0),
    );

    const rowsEmptyShippingType = projects.filter(
      proj => proj.shipping_type === '',
    );

    const rowsEmptyFirstRequest = projects.filter(
      proj => proj.first_request === '',
    );

    const rowsConflictError = projects.filter(
      proj => proj.conflict_error !== '',
    );

    if (projects.length <= 0) {
      setTitleNotice('Lotes inválidos!');
      setSubTitleNotice('Necessário informar lotes!');
      setIsOpenNotice(true);
    } else if (rowsEmptyClient && rowsEmptyClient.length > 0) {
      setTitleNotice('Existem clientes inválidos!');
      setSubTitleNotice('Não é possível enviar lotes sem cliente selecionado!');
      setIsOpenNotice(true);
    } else if (rowsEmptyFolder && rowsEmptyFolder.length > 0) {
      setTitleNotice('Existem pastas inválidas!');
      setSubTitleNotice('Não é possível enviar lotes sem pastas selecionadas!');
      setIsOpenNotice(true);
    } else if (rowsInvalidTotalAmount && rowsInvalidTotalAmount.length > 0) {
      setTitleNotice('Existem quantidades inválidas!');
      setSubTitleNotice(
        'Não é possível enviar lotes sem quantidade informada!',
      );
      setIsOpenNotice(true);
    } else if (rowsEmptyShippingType && rowsEmptyShippingType.length > 0) {
      setTitleNotice('Existem tipos de fretes inválidos!');
      setSubTitleNotice(
        'Não é possível enviar lotes sem tipos de fretes selecionados!',
      );
      setIsOpenNotice(true);
    } else if (rowsEmptyFirstRequest && rowsEmptyFirstRequest.length > 0) {
      setTitleNotice('Existem informações de 1º pedido inválidos!');
      setSubTitleNotice(
        'Não é possível enviar lotes sem informar se é 1º pedido ou não!',
      );
      setIsOpenNotice(true);
    } else if (rowsConflictError && rowsConflictError.length > 0) {
      setTitleNotice('Existem registros inválidos!');
      setSubTitleNotice(
        'Não é possível enviar lotes com erros em clientes e pastas!',
      );
      setIsOpenNotice(true);
    } else {
      setTitleConfirmProcedure(`Confirma gravação dos lotes?`);
      setIsOpenConfirmProcedure(true);
    }
  }

  function onConfirmProcedure(): void {
    setValue('projects', projects);

    handleSubmit(submitForm)();
  }

  const onExit = useCallback(() => {
    history.push('/projects/folders');
  }, [history]);

  function handleAddRowTableProjects(): void {
    projectSequence.current += 1;

    const newProject: IProject = {
      sequence: projectSequence.current,
      type: 'PASTA',
      item_total_amount: 0,
      erp_client_code: '',
      erp_rep_code: '',
      folder: { value: '' },
      shipping_type: '',
      first_request: '',
      user_inclusion_id: user.id,
      user_update_id: user.id,
      conflict_error: '',
    };

    setProjects(prevProjects => [...prevProjects, newProject]);
  }

  function handleDeleteRowTableProjects(sequence: number): void {
    setProjects(projects.filter(proj => proj.sequence !== sequence));
  }

  useEffect(() => {
    Object.keys(projects).forEach(key => {
      let conflict = false;

      const projConflict = projects.filter(
        project =>
          project.sequence !== projects[Number(key)].sequence &&
          project.erp_client_code !== '' &&
          project.erp_client_code === projects[Number(key)].erp_client_code &&
          project.folder.value !== '' &&
          project.folder.value === projects[Number(key)].folder.value,
      );

      if (projConflict && projConflict.length > 0) {
        conflict = true;
      }

      if (conflict && projects[Number(key)].conflict_error === '') {
        setProjects(
          projects.map(proj => {
            if (proj.sequence === projects[Number(key)].sequence) {
              proj.conflict_error = 'Cliente e pasta já informados';
            }

            return proj;
          }),
        );
      } else if (!conflict && projects[Number(key)].conflict_error !== '') {
        setProjects(
          projects.map(proj => {
            if (proj.sequence === projects[Number(key)].sequence) {
              proj.conflict_error = '';
            }

            return proj;
          }),
        );
      }
    });
  }, [projects]);

  async function handleRowClientChange(
    sequence: number,
    fieldValue: string,
  ): Promise<void> {
    if (clients) {
      const selectedClient = clients.find(
        selected => selected.code === fieldValue,
      );

      if (selectedClient) {
        setProjects(
          projects.map(proj => {
            if (proj.sequence === sequence) {
              proj.erp_client_code = fieldValue;
              proj.erp_rep_code = selectedClient.rep_code;
            }
            return proj;
          }),
        );
      }
    }
  }

  async function handleRowFolderChange(
    sequence: number,
    fieldValue: string,
  ): Promise<void> {
    setProjects(
      projects.map(proj => {
        if (proj.sequence === sequence) {
          proj.folder.value = fieldValue;
        }
        return proj;
      }),
    );
  }

  function handleRowItemTotalAmountChange(
    sequence: number,
    fieldValue: string,
  ): void {
    let itemTotalAmountConv = fieldValue;
    itemTotalAmountConv = itemTotalAmountConv.replaceAll('.', '');
    itemTotalAmountConv = itemTotalAmountConv.replaceAll(',', '.');

    let itemTotalAmountValue = 0;

    if (!Number.isNaN(Number(parseFloat(itemTotalAmountConv)))) {
      itemTotalAmountValue = parseFloat(itemTotalAmountConv);
    }

    setProjects(
      projects.map(proj => {
        if (proj.sequence === sequence) {
          proj.item_total_amount = itemTotalAmountValue;
        }
        return proj;
      }),
    );
  }

  async function handleRowShippingTypeChange(
    sequence: number,
    fieldValue: string,
  ): Promise<void> {
    setProjects(
      projects.map(proj => {
        if (proj.sequence === sequence) {
          proj.shipping_type = fieldValue;
        }
        return proj;
      }),
    );
  }

  async function handleRowFirstRequestChange(
    sequence: number,
    fieldValue: string,
  ): Promise<void> {
    setProjects(
      projects.map(proj => {
        if (proj.sequence === sequence) {
          proj.first_request = fieldValue;
        }
        return proj;
      }),
    );
  }

  function handleProjectsTable(): React.ReactNode | null {
    if (projects) {
      if (projects.length > 0) {
        return (
          <Table>
            {projects?.map(project => (
              <tr key={project.sequence}>
                <Separator />

                <ItemContent>
                  <ItemWrapper>
                    <TableSelect
                      label="Cliente"
                      upperCaseLabel
                      options={clientOptions}
                      loading={loadingClients}
                      error={project.conflict_error}
                      ref={register}
                      onSet={(e: any) => {
                        handleRowClientChange(project.sequence, e.value);
                      }}
                    />
                  </ItemWrapper>

                  <ItemWrapper>
                    <TableSelect
                      label="Pasta"
                      upperCaseLabel
                      options={folderOptions}
                      loading={loadingFolders}
                      error={project.conflict_error}
                      ref={register}
                      onSet={(e: any) => {
                        handleRowFolderChange(project.sequence, e.value);
                      }}
                    />
                  </ItemWrapper>
                </ItemContent>

                <ItemContent>
                  <ItemWrapper>
                    <TableWholeNumberInput
                      labelText="Quantidade"
                      upperCaseLabel
                      type="text"
                      defaultValue={project.item_total_amount}
                      onSet={e => {
                        handleRowItemTotalAmountChange(
                          project.sequence,
                          e.target.value,
                        );
                      }}
                    />
                  </ItemWrapper>

                  <ItemWrapper>
                    <TableSelect
                      label="Frete"
                      upperCaseLabel
                      options={shippingTypeOptions}
                      ref={register}
                      onSet={(e: any) => {
                        handleRowShippingTypeChange(project.sequence, e.value);
                      }}
                    />
                  </ItemWrapper>
                </ItemContent>

                <ItemContent>
                  <ItemWrapper>
                    <TableSelect
                      label="1º Pedido"
                      upperCaseLabel
                      options={firstRequestOptions}
                      ref={register}
                      onSet={(e: any) => {
                        handleRowFirstRequestChange(project.sequence, e.value);
                      }}
                    />
                  </ItemWrapper>
                </ItemContent>

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '10px',
                  }}
                >
                  <DeleteRowButton
                    type="button"
                    onClick={() => {
                      handleDeleteRowTableProjects(project.sequence);
                    }}
                  />
                </div>
              </tr>
            ))}
          </Table>
        );
      }
    }

    return null;
  }

  return (
    <Container>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChildPath="/projects/folders"
        firstChild="Pastas"
        secondChild="Novos Lotes"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaFolder}
        secondChildIcon={FaLayerGroup}
        mainColored
        firstChildColored
      />

      <form onSubmit={handleSubmit(submitForm)}>
        <div
          style={{
            width: '100%',
            maxWidth: '680px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Label>Lotes</Label>
        </div>

        <Content>{handleProjectsTable()}</Content>

        <ContentAddRow>
          <AddRowButton
            type="button"
            onClick={() => handleAddRowTableProjects()}
          />
        </ContentAddRow>

        <ButtonContainer>
          <SaveButton
            type="button"
            onClick={() => onValidateConfirmProcedure()}
          />

          <ExitButton
            marginLeft="1rem"
            type="button"
            onClick={() => setIsOpenExit(true)}
          />
        </ButtonContainer>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={onConfirmProcedure}
        title={titleConfirmProcedure}
        subTitle=""
        opConfirm="Sim"
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />

      <NoticeDialog
        isOpen={isOpenNotice}
        type="error"
        title={titleNotice}
        subTitle={subTitleNotice}
        setIsOpen={setIsOpenNotice}
      />
    </Container>
  );
};

export default Batches;
