import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

export interface ISaveProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
}

const Save: React.FC<ISaveProps> = ({ label, ...rest }) => {
  return (
    <Container {...rest}>{label !== undefined ? label : 'Salvar'}</Container>
  );
};

export default Save;
