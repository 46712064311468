import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

export interface IRejectProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  marginLeft?: string;
}

const Reject: React.FC<IRejectProps> = ({ label, ...rest }) => {
  return (
    <Container {...rest}>{label !== undefined ? label : 'Recusar'}</Container>
  );
};

export default Reject;
