import React, { useCallback, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FaTools, FaWrench } from 'react-icons/fa';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { string, number } from 'yup';

import BreadCrumb from '~/components/BreadCrumb';
import ProjectInfo from '~/components/ProjectInfo/Default';
import DefaultInput from '~/components/Inputs/Default';
import CurrencyInput from '~/components/Inputs/Currency';
import TextArea from '~/components/TextArea';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';
import NoticeDialog from '~/components/Dialogs/Notice';

import { getCurrencyFormatValue } from '~/utils';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  Content,
  ContentSplit,
  Wrapper,
  HalfWrapper,
  ButtonContainer,
} from './styles';

interface IFormDiscountCancellation {
  canceled_discount_price: number;
  reason: string;
}

interface ILocation {
  id: string;
  type: string;
  code: string;
  pending_discount_price: number;
}

const schema = Yup.object().shape({
  canceled_discount_price: Yup.string().when((value: string) => {
    if (Number.isNaN(Number(parseFloat(value)))) {
      return string().required('Valor inválido');
    }
    if (parseFloat(value) <= 0) {
      return number()
        .min(0.1, 'Valor inválido')
        .required('Valor inválido')
        .typeError('Valor inválido');
    }

    return string().notRequired();
  }),
  reason: Yup.string().required('Campo obrigatório'),
});

const DiscountCancellation: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [titleConfirmProcedure, setTitleConfirmProcedure] = useState('');
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [isOpenNotice, setIsOpenNotice] = useState(false);
  const [titleNotice, setTitleNotice] = useState('');
  const [subTitleNotice, setSubTitleNotice] = useState('');

  const { handleSubmit, register, getValues, setValue, errors } =
    useForm<IFormDiscountCancellation>({
      resolver: yupResolver(schema),
    });

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Dados não localizados',
      });

      history.push('/management/main');
    } else if (state.type !== 'PATROCINIO') {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Apenas disponível para solicitações de Pagamentos',
      });

      history.push('/management/main');
    } else if (user.user_type.type !== 'ADM') {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Tipo de usuário não permitido',
      });

      history.push('/management/main');
    }
  }, [state, addToast, history, user, register, setValue]);

  const submitForm = useCallback(
    async (data: IFormDiscountCancellation) => {
      try {
        if (state && state.id) {
          await api.put(
            `/projects/updatecanceleddiscountprice/${state.id}`,
            data,
          );

          addToast({
            type: 'success',
            title: 'Solicitação atualizada!',
            description: 'Solicitação atualizada com sucesso.',
          });
        }

        history.push('/management/main');
      } catch (error: any) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [state, addToast, history],
  );

  async function onValidateConfirmProcedure(): Promise<void> {
    const canceledDiscountPrice = getCurrencyFormatValue(
      getValues('canceled_discount_price'),
    );

    if (Number.isNaN(Number(canceledDiscountPrice))) {
      setTitleNotice('Valor inválido para cancelamento!');
      setSubTitleNotice(
        'Deve ser informado um valor válido para cancelamento!',
      );
      setIsOpenNotice(true);
    } else if (
      Math.round(state.pending_discount_price * 100) / 100 <
      canceledDiscountPrice
    ) {
      setTitleNotice('Valor inválido para cancelamento!');
      setSubTitleNotice(
        'O valor cancelado não pode ser acima do valor pendente!',
      );
      setIsOpenNotice(true);
    } else {
      setTitleConfirmProcedure(
        `Confirma cancelamento de desconto na solicitação ${state.code}?`,
      );
      setIsOpenConfirmProcedure(true);
    }
  }

  function onConfirmProcedure(): void {
    const canceledDiscountPrice = getCurrencyFormatValue(
      getValues('canceled_discount_price'),
    );

    if (!Number.isNaN(Number(canceledDiscountPrice))) {
      setValue('canceled_discount_price', canceledDiscountPrice);
    }

    handleSubmit(submitForm)();
  }

  const onExit = useCallback(() => {
    history.push('/management/main');
  }, [history]);

  return (
    <Container>
      <BreadCrumb
        main="Administração"
        path="/management/main"
        firstChild="Alteração Manual de Solicitação"
        mainIcon={FaTools}
        firstChildIcon={FaWrench}
        mainColored
      />

      <ProjectInfo project_id={state.id} all />

      <form onSubmit={handleSubmit(submitForm)}>
        <ContentSplit maxWidth="600px">
          <HalfWrapper>
            <DefaultInput
              name="pending_discount_price"
              labelFor="pending_discount_price"
              labelText="Valor de Desconto Pendente"
              type="text"
              value={new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(state.pending_discount_price)}
              disabled
            />
          </HalfWrapper>

          <HalfWrapper>
            <CurrencyInput
              name="canceled_discount_price"
              labelFor="canceled_discount_price"
              labelText="Valor de Cancelamento"
              defaultValue={0}
              error={errors?.canceled_discount_price?.message}
              ref={register}
            />
          </HalfWrapper>
        </ContentSplit>

        <Content maxWidth="600px">
          <Wrapper>
            <TextArea
              name="reason"
              labelFor="reason"
              labelText="Motivo"
              error={errors?.reason?.message}
              ref={register}
              maxLength={1000}
            />
          </Wrapper>
        </Content>

        <ButtonContainer>
          <SaveButton
            type="button"
            onClick={() => onValidateConfirmProcedure()}
          />

          <ExitButton
            marginLeft="1rem"
            type="button"
            onClick={() => setIsOpenExit(true)}
          />
        </ButtonContainer>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={onConfirmProcedure}
        title={titleConfirmProcedure}
        subTitle=""
        opConfirm="Sim"
        type=""
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />

      <NoticeDialog
        isOpen={isOpenNotice}
        type="error"
        title={titleNotice}
        subTitle={subTitleNotice}
        setIsOpen={setIsOpenNotice}
      />
    </Container>
  );
};

export default DiscountCancellation;
